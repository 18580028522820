import { addColorClass } from "../cb/colorClasses"
import { createDmWindowRequest } from "../cb/components/pm/dmWindowsManager"
import { pageContext, roomDossierContext } from "../cb/interfaces/context"
import { isAnonymous, isNotLoggedIn } from "./auth"
import { roomLoaded } from "./context"
import { Component } from "./defui/component"
import { applyStyles } from "./DOMutils"
import { ChatTabContainer } from "./theatermodelib/chatTabContainer"
import { EmojiSelectionModal } from "./theatermodelib/emojiSelectionModal"
import { ResizeHandle } from "./theatermodelib/resizeHandle"
import { loginOverlayRequest, openDefaultTipCalloutRequest } from "./theatermodelib/userActionEvents"
import { VideoPanel } from "./theatermodelib/videoPanel"
import { i18n } from "./translation"
import { standardEmojiRequest } from "./userActionEvents"
import { VideoMode, videoModeHandler } from "./videoModeHandler"
import type { IRoomContext } from "./context"
import type { DraggableCanvas } from "./fullvideolib/draggableCanvas"
import type { DismissibleMessages } from "./theatermodelib/dismissibleMessages"
import type { TheaterModePlayer } from "./theatermodelib/theaterModePlayer"
import type { GameInfoBar } from "../cb/components/games/gameInfoBar"

export class BaseRoomContents extends Component {
    protected player: TheaterModePlayer
    public draggableCanvas: DraggableCanvas
    public topSectionWrapper: HTMLDivElement
    public videoPanel: VideoPanel
    public resizeHandle: ResizeHandle
    public chatTabContainer: ChatTabContainer
    readonly roomContentsPadding = 4 
    public gameInfoBar: GameInfoBar
    public dismissibleMessages: DismissibleMessages
    private offlineContentContainer: HTMLDivElement
    public sendTipButton: HTMLSpanElement
    private sendDMButton: HTMLSpanElement
    public emojiSelectionModal: EmojiSelectionModal | undefined

    constructor(player: TheaterModePlayer, draggableCanvas: DraggableCanvas) {
        super()

        this.player = player
        this.draggableCanvas = draggableCanvas
        addColorClass(this.element, "BaseRoomContents")
        this.element.style.position = "static"
        this.element.style.display = "block"
        this.element.style.height = "auto"
        this.element.style.borderRadius = "4px"
        this.element.style.padding = `${this.roomContentsPadding}px`
        this.element.style.margin = "12px 0 17px 12px"
        this.element.style.overflow = "visible"
        this.element.style.boxSizing = "border-box"

        this.offlineContentContainer = document.createElement("div")
        this.offlineContentContainer.dataset.testid = "offline-content-container"
        addColorClass(this.offlineContentContainer, "offlineContentContainer")
        this.offlineContentContainer.style.display = "none"
        this.element.appendChild(this.offlineContentContainer)

        const offlineRoomNotice = document.createElement("div")
        offlineRoomNotice.dataset.testid = "offline-room-notice"
        addColorClass(offlineRoomNotice, "offlineRoomNotice")
        offlineRoomNotice.innerText = i18n.roomOfflineMessage
        offlineRoomNotice.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"
        offlineRoomNotice.style.fontSize = "12px"
        offlineRoomNotice.style.fontWeight = "bold"
        offlineRoomNotice.style.margin = "13px 0"
        this.offlineContentContainer.appendChild(offlineRoomNotice)

        this.sendTipButton = this.createSendButton(i18n.sendTipButtonCAPS)
        this.sendTipButton.dataset.testid = "send-tip-button"
        addColorClass(this.sendTipButton, "sendTipButton")
        this.sendTipButton.style.display = "none"
        this.sendTipButton.onclick = () => {
            openDefaultTipCalloutRequest.fire({})
        }
        this.offlineContentContainer.appendChild(this.sendTipButton)

        this.sendDMButton = this.createSendButton(i18n.sendDmCAPS)
        addColorClass(this.sendDMButton, "sendDMButton")
        this.sendDMButton.style.display = "none"
        this.sendDMButton.onclick = () => {
            if (isNotLoggedIn(i18n.loggedInToDm)) {
                return
            }
            createDmWindowRequest.fire(roomDossierContext.getState().room)
        }
        this.offlineContentContainer.appendChild(this.sendDMButton)

        this.topSectionWrapper = document.createElement("div")
        addColorClass(this.topSectionWrapper, "topSectionWrapper")
        this.topSectionWrapper.style.width = "100%"
        this.topSectionWrapper.style.height = "auto"
        this.topSectionWrapper.style.position = "relative"
        this.topSectionWrapper.style.display = "grid"
        this.topSectionWrapper.style.gridTemplateColumns = "auto auto 1fr"
        this.element.appendChild(this.topSectionWrapper)

        this.videoPanel = this.addChild(new VideoPanel(this.player, this.draggableCanvas), this.topSectionWrapper)
        this.resizeHandle = this.addChild(this.createResizeHandle(), this.topSectionWrapper)
        this.chatTabContainer = this.addChild(new ChatTabContainer(), this.topSectionWrapper)

        standardEmojiRequest.listen((clickedElement: HTMLElement) => {
            if (isAnonymous()) {
                loginOverlayRequest.fire(true)
                return
            }
            if (videoModeHandler.getVideoMode() !== VideoMode.Split) {
                return
            }
            if (this.emojiSelectionModal === undefined) {
                this.emojiSelectionModal = new EmojiSelectionModal(() => this.chatTabContainer.getCurrentTab().getChatInputField())
            }
            this.emojiSelectionModal.show(clickedElement)
        })

        roomLoaded.listen((context: IRoomContext) => {
            if (context.dossier.isAgeVerified) {
                this.sendTipButton.style.display = "inline-block"
            }
            if (context.dossier.room !== pageContext.current.loggedInUser?.username) {
                this.sendDMButton.style.display = "inline-block"
            }
        })
    }

    public showOfflineContent(): void {
        this.topSectionWrapper.style.position = "fixed"
        this.topSectionWrapper.style.top = "999999px"
        this.dismissibleMessages.element.style.display = "none"
        this.offlineContentContainer.style.display = "block"
    }

    public hideOfflineContent(): void {
        this.topSectionWrapper.style.position = "relative"
        this.topSectionWrapper.style.top = ""
        this.dismissibleMessages.element.style.display = "block"
        this.offlineContentContainer.style.display = "none"
    }

    private createSendButton(text: string): HTMLSpanElement {
        const button = document.createElement("span")
        button.dataset.testid = "send-dm-button"
        button.textContent = text
        button.title = text
        applyStyles(button, {
            display: "inline-block",
            minWidth: "74px",
            textAlign: "center",
            fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
            padding: "4px 10px",
            margin: "11px 4px 13px 0px",
            boxSizing: "border-box",
            cursor: "pointer",
            borderWidth: "1px",
            borderStyle: "solid",
        })
        return button
    }

    protected createResizeHandle(): ResizeHandle {
        return new ResizeHandle(this)
    }

    protected repositionChildren(): void {
        this.chatTabContainer.element.style.width = `${this.element.clientWidth - this.videoPanel.element.offsetWidth - 16}px`
    }

    public getAvailableWidth(): number {
        return this.element.clientWidth - this.roomContentsPadding * 2
    }
}
