import { getLocalStorageWithExpiration, setLocalStorageWithExpiration } from "@multimediallc/web-utils/storage"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { currentSiteSettings } from "../../cb/siteSettings"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { postCb } from "../api"
import { Component } from "../defui/component"
import { EventRouter } from "../events"
import { i18n } from "../translation"

export const enum NoticeType {
    Persist,
    NoPersist,
    Ajax,
}

interface ISiteNoticeOptions {
    noticeType: NoticeType,
    ajaxUrl: string,
    cacheKey: string,
    cacheDurationDays: number,
}

export class SiteNotice extends Component {
    private dismissLink: HTMLAnchorElement
    private options: ISiteNoticeOptions
    public messageRemoved = new EventRouter<undefined>("messageRemoved")

    constructor(content: string, options: Partial<ISiteNoticeOptions> = {}) {
        super()
        addColorClass(this.element, "siteNotice")
        this.element.style.display = "none"
        this.element.style.position = "static"

        const defaultOptions = {
            noticeType: NoticeType.Persist,
            cacheKey: "",
            ajaxUrl: "",
            cacheDurationDays: 10,
        }

        this.options = {
            ...defaultOptions,
            ...options,
        }

        if (this.options.noticeType === NoticeType.Persist && this.options.cacheKey === "") {
            error("SiteNotice: Must provide a CacheKey when used in Persist mode.")
        }

        if (this.options.noticeType === NoticeType.Ajax && this.options.ajaxUrl === "") {
            error("SiteNotice: Must provide an AjaxUrl when used in Ajax mode.")
        }

        this.render(content)
    }

    private render(content: string): void {
        if (this.options.cacheKey !== "") {
            const cacheValue = getLocalStorageWithExpiration(this.options.cacheKey)
            if (cacheValue === "1") {
                return
            }
        }
        this.element.style.display = "block"
        this.element.style.height = "auto"
        this.element.style.position = "static"
        this.element.style.padding = "5px 0px"
        this.element.style.textAlign = "center"
        this.element.style.boxSizing = "border-box"
        this.element.style.fontSize = "14px"
        this.element.style.fontWeight = "400"
        this.element.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        this.element.style.color = currentSiteSettings.dismissibleMessageColor

        const wrapperDiv = document.createElement("div")
        addColorClass(wrapperDiv, "wrapper")
        wrapperDiv.style.padding = "15px"
        wrapperDiv.style.borderWidth = "1px"
        wrapperDiv.style.borderStyle = "solid"
        const noticeTextDiv = document.createElement("div")
        noticeTextDiv.innerHTML = content // eslint-disable-line @multimediallc/no-inner-html
        this.dismissLink = document.createElement("a")
        addColorClass(this.dismissLink, colorClass.hrefColor)
        this.dismissLink.textContent = i18n.dismissLower
        this.dismissLink.style.cursor = "pointer"
        const leftParen = document.createElement("span")
        leftParen.textContent = " ("
        const rightParen = document.createElement("span")
        rightParen.textContent = ")"

        noticeTextDiv.appendChild(leftParen)
        noticeTextDiv.appendChild(this.dismissLink)
        noticeTextDiv.appendChild(rightParen)
        wrapperDiv.appendChild(noticeTextDiv)
        this.element.appendChild(wrapperDiv)

        const links = noticeTextDiv.getElementsByTagName("a")
        for (const link of links ) {
            addColorClass(link, colorClass.hrefColor)
            link.onmouseenter = () => {
                link.style.textDecoration = "underline"
            }
            link.onmouseleave = () => {
                link.style.textDecoration = ""
            }
        }

        addEventListenerPoly("click", this.dismissLink, () => {
            this.element.style.display = "none"
            this.element.removeChild(wrapperDiv)
            switch (this.options.noticeType) {
                case NoticeType.Persist:
                    setLocalStorageWithExpiration(this.options.cacheKey, "1", { days: this.options.cacheDurationDays })
                    break
                case NoticeType.NoPersist:
                    break
                case NoticeType.Ajax:
                    postCb(this.options.ajaxUrl, {})  // eslint-disable-line @typescript-eslint/no-floating-promises
                    break
            }
            this.messageRemoved.fire(undefined)
        })
    }
}
