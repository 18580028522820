import { appPanelHeight, appPanelWidth } from "../appPanel"
import { Component } from "../defui/component"
import { applyStyles, numberFromStyle } from "../DOMutils"
import { dom } from "../tsxrender/dom"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { resizeHandleWidth } from "./resizeHandle"
import type { TheaterModeRoomContents } from "./theaterModeRoomContents"

export interface IRoomContentsPlaceholderProps {roomContents: TheaterModeRoomContents}

export class RoomContentsPlaceholder extends Component<HTMLDivElement> {
    private roomContents: TheaterModeRoomContents
    private videoPanel: HTMLDivElement
    private videoPlaceholder: HTMLDivElement
    private chatPlaceholder: HTMLDivElement
    private bottomPanel: HTMLDivElement

    constructor(props: IRoomContentsPlaceholderProps) {
        super("div", props)
    }

    initUI(props: IRoomContentsPlaceholderProps): void {
        super.initUI(props)
        const roomContentsStyles: CSSX.Properties = {
            margin: "12px 12px 17px",
            padding: "4px",
        }
        const videoChatSectionStyles: CSSX.Properties = {
            display: "flex",
            flexDirection: "row",
        }

        const videoPanelStyles: CSSX.Properties = { marginRight: `${resizeHandleWidth}px` }

        const bottomPanelStyles: CSSX.Properties = {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
        }

        this.element = 
            <div style={roomContentsStyles} className="roomContentsPlaceholder" data-testid = "room-contents-placeholder">
                <div style={videoChatSectionStyles}>
                    <div ref={(el: HTMLDivElement) => this.videoPanel = el} style={videoPanelStyles} data-testid = "room-contents-placeholder-video-panel">
                        <div className="placeholderLine gray-bg" style={{ width: "100%", height: "34px", margin: "0" }}></div>
                        <div ref={(el: HTMLDivElement) => this.videoPlaceholder = el} style={{ backgroundColor: "#000" }} data-testid = "room-contents-placeholder-video">&nbsp;</div>
                        <div ref={(el: HTMLDivElement) => this.bottomPanel = el} style={bottomPanelStyles} data-testid = "room-contents-placeholder-bottom-panel">
                            <div className="placeholderBox gray-bg" style={{ width: `${appPanelWidth}px`, height: `${appPanelHeight}px` }}></div>
                            <div style={{ margin: "2px 10px" }}>
                                <div className="placeholderLine gray-bg" style={{ width: "206px" }}></div>
                                <div className="placeholderLine gray-bg" style={{ width: "130px" }}></div>
                                <div className="placeholderLine gray-bg" style={{ width: "130px" }}></div>
                            </div>
                        </div>
                    </div>
                    <div ref={(el: HTMLDivElement) => this.chatPlaceholder = el} className="placeholderBox gray-bg" style={{ flexGrow: 1 }} data-testid = "room-contents-placeholder-chat">&nbsp;</div>
                </div>
                <div className ="placeholderBox gray-bg" style={{ marginTop: "5px", height: "422px", width: "100%" }}></div>
            </div>
        

        this.updateAppearance()
    }

    initData(props: IRoomContentsPlaceholderProps): void {
        super.initData(props)
        this.roomContents = props.roomContents
    }

    private updateAppearance(): void {
        const isTheatermode = videoModeHandler.getVideoMode() === VideoMode.Theater
        this.calculateAndSetVideoDimensions(isTheatermode)
        this.adjustStyles(isTheatermode)
    }

    private calculateAndSetVideoDimensions(isTheatermode: boolean): void {
        let videoWidth, videoHeight: number
        if (isTheatermode) {
            ({ videoWidth, videoHeight } = this.roomContents.calculateTheaterModeDimensions())
            videoWidth = Math.max(videoWidth, this.roomContents.getAvailableWidth())
        } else {
            videoWidth = this.roomContents.resizeHandle.calculateSplitModeVideoPanelWidth()
            const realVideoPanelStyles = getComputedStyle(this.roomContents.videoPanel.element)
            const realVideoWidth = videoWidth - numberFromStyle(realVideoPanelStyles.borderLeftWidth) - numberFromStyle(realVideoPanelStyles.borderRightWidth)
            videoHeight = this.roomContents.resizeHandle.calculateHeightFromWidth(realVideoWidth)
        }
        // set video width to video panel width instead of real video width since placeholder video panel does not
        // have borders
        applyStyles(this.videoPlaceholder, {
            width: `${videoWidth}px`,
            height: `${videoHeight}px`,
        })
        this.videoPanel.style.maxWidth = `${videoWidth}px`
    }

    private adjustStyles(isTheatermode: boolean): void {
        applyStyles(this.element, {
            marginLeft: `${numberFromStyle(this.roomContents.element.style.marginLeft)}px`,
            marginRight: `${numberFromStyle(this.roomContents.element.style.marginRight)}px`,
        })

        if (isTheatermode) {
            this.chatPlaceholder.style.display = "none"
            this.bottomPanel.style.display = "none"
        } else {
            this.chatPlaceholder.style.display = ""
            this.bottomPanel.style.display = "flex"
        }
    }

    public show(): void {
        this.updateAppearance()
        this.showElement()
    }
}
