import { isRoomRoomlistSpaActive, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { tokenBalanceUpdate } from "../../cb/api/tipping"
import { addColorClass, colorClass, removeColorClass } from "../../cb/colorClasses"
import { PromoteRoomLink } from "../../cb/components/promoteRoom/promoteRoomLink"
import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { isRoomRoomlistSpaEligiblePage } from "../../cb/components/roomlist/spaHelpers"
import { setupShowMyCamLink } from "../../cb/components/showMyCam/smcViewer"
import { roomDossierContext } from "../../cb/interfaces/context"
import { resizeDebounceEvent } from "../../cb/ui/responsiveUtil"
import { Wrapper } from "../../cb/ui/wrapper"
import { modalConfirm } from "../alerts"
import { AppPanelCarousel, appPanelHeight, appPanelWidth, multiAppPanel } from "../appPanel"
import { waitAfterRoomLoadMilliseconds } from "../appPanelChat"
import { roomCleanup, roomLoaded } from "../context"
import { Component } from "../defui/component"
import { applyStyles, createHoverText, hoverEvent } from "../DOMutils"
import { ListenerGroup } from "../events"
import { featureFlagIsActive } from "../featureFlag"
import { privateWindowRequest } from "../fullvideolib/userActionEvents"
import { switchedToHLS } from "../mobilelib/userActionEvents"
import { enterPrivateShowAlertChain, enterSpyShowAlertChain, leavePrivateOrSpyShowAlertChain } from "../privateShow"
import { ignoreCatch } from "../promiseUtils"
import { areRoomsInSync, syncRooms } from "../roomDossier"
import { RoomStatus } from "../roomStatus"
import { i18n } from "../translation"
import { UserContextMenu } from "../userContextMenu"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { isPrivateShowRequestFeatureActive } from "./privateShowRequestModal"
import { preferredMinVideoWidth } from "./resizeHandle"
import { openDefaultTipCalloutRequest, privateShowRequestOverlayDismiss, privateShowSplitModeRequest, resetPrivateShowLink } from "./userActionEvents"
import type { TheaterModePlayer } from "./theaterModePlayer"
import type { ReactComponent } from "../../cb/components/ReactRegistry"
import type { AppPanel } from "../appPanel"
import type { IChatConnection, IRoomContext } from "../context"
import type { BoundListener } from "../events"
import type { DraggableCanvas } from "../fullvideolib/draggableCanvas"
import type { IRoomStatusChangeNotification } from "../messageInterfaces"
import type { IVideoModeChangeNotification } from "../videoModeHandler"

export class VideoPanel extends Component<HTMLDivElement> {
    public topSection: HTMLDivElement
    public appPanelHolder: HTMLSpanElement
    public appPanel: AppPanel | AppPanelCarousel
    public tipSection: HTMLDivElement
    public tokenBalance: HTMLSpanElement
    public tokenBalanceSuffix: HTMLSpanElement
    public sendTipButton: HTMLSpanElement
    private privateShowLink: HTMLAnchorElement
    private privateShowDiv: HTMLDivElement
    private spyDisabledWarning: HTMLParagraphElement
    private isAgeVerified: boolean
    private leavePrivateOrSpyPending = false
    private bottomSection: Wrapper
    private hasWaitAfterRoomLoadElapsed = false
    private draggableCanvas: DraggableCanvas
    private bottomSectionDisplay: string
    private isBroadcaster: boolean
    private roomSubject: ReactComponent
    private privateHoverEvent: BoundListener<boolean> | undefined
    listenerGroup: ListenerGroup

    private privateShowActive = false
    private privateShowReqestPending = false

    constructor(public player: TheaterModePlayer, draggableCanvas: DraggableCanvas) {
        super()
        this.draggableCanvas = draggableCanvas

        this.element.id = "VideoPanel"
        this.element.dataset.testid = "video-panel"
        this.element.style.position = ""
        this.element.style.display = "inline-block"
        this.element.style.borderRadius = "2px"
        this.element.style.boxSizing = "border-box"
        this.element.style.height = "auto"
        this.element.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        this.element.style.fontSize = "11px"
        this.element.style.overflow = "visible"

        UserContextMenu.setUCMContainer(this.element)

        this.topSection = document.createElement("div")
        this.topSection.className = "playerTitleBar"
        // hide roomName and report link before room is loaded to prevent incorrect info on screen
        if (isRoomRoomlistSpaActive()) {
            this.hideSubjectContainerContents()
        }
        this.element.appendChild(this.topSection)

        const abuseLink = document.createElement("div")
        this.topSection.appendChild(abuseLink)
        const AbuseLinkSectionClass = ReactComponentRegistry.get("AbuseLinkSection")
        const abuseLinkInstance = new AbuseLinkSectionClass(
            { "roomName": "" },
            abuseLink,
        )
        applyStyles(abuseLink,{
            margin: "0px 7px -5px 0px",
            paddingTop: "3px",
            fontFamily: "UbuntuMedium,Helvetica,Arial,sans-serif",
            fontSize: "10px",
        })

        const roomSubject = document.createElement("div")
        roomSubject.dataset.testid = "roomSubjectContainer"
        this.topSection.appendChild(roomSubject)
        const RoomSubjectClass = ReactComponentRegistry.get("RoomSubject")
        const roomSubjectInstance = new RoomSubjectClass({
            text: "",
            toolTipWidth: preferredMinVideoWidth,
            roomName: "",
            onHashtagClick: isRoomRoomlistSpaEligiblePage() ? (ev: MouseEvent) => {
                ev.preventDefault()
                UrlState.current.navigateTo((ev.currentTarget as HTMLAnchorElement).href)
            } : undefined,
        }, roomSubject)
        roomSubject.style.fontFamily = "UbuntuMedium,Helvetica,Arial,sans-serif"
        this.roomSubject = roomSubjectInstance

        this.player.addDraggableCanvas(draggableCanvas)
        this.addChild(this.player)

        this.bottomSection = new Wrapper()
        if (multiAppPanel) {
            applyStyles(this.bottomSection.element, {
                display: "flex",
                flexWrap: "wrap-reverse",
                justifyContent: "space-between",
            })
        }
        this.bottomSection.element.style.overflow = ""
        this.bottomSectionDisplay = this.bottomSection.element.style.display
        this.addChild(this.bottomSection)
        this.appPanelHolder = document.createElement("span")
        this.appPanelHolder.style.width = multiAppPanel ? "320px" : `${appPanelWidth}px`
        this.appPanelHolder.style.height = `${appPanelHeight}px`
        this.appPanelHolder.style.display = "inline-block"
        this.appPanelHolder.style.verticalAlign = "top"
        this.appPanelHolder.style.margin = "0"
        this.bottomSection.element.appendChild(this.appPanelHolder)
        this.appPanel = this.getAppPanel()

        this.appPanel.panelUpdated.listen(() => {
            if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                while (this.appPanelHolder.firstChild !== null) {
                    this.appPanelHolder.removeChild(this.appPanelHolder.firstChild)
                }
                if (multiAppPanel) {
                    this.appPanelHolder.appendChild(this.appPanel.element)
                } else {
                    this.appPanelHolder.appendChild(this.appPanel.element.cloneNode(true))
                }
            }
        })

        this.tipSection = document.createElement("div")
        addColorClass(this.tipSection, colorClass.defaultColor)
        this.tipSection.style.height = "69px"
        this.tipSection.style.boxSizing = "border-box"
        this.tipSection.style.fontSize = "11px"
        this.tipSection.style.overflow = "hidden"
        this.tipSection.style.display = "inline-block"
        this.tipSection.style.verticalAlign = "top"
        this.tipSection.style.margin = "0"
        this.bottomSection.element.appendChild(this.tipSection)
        this.tipSection.dataset.paction = "CurrentShowBuyBox"
        this.tipSection.dataset.testid= "buy-box"
        if (featureFlagIsActive("PremPrivShow")) {
            this.tipSection.style.overflow = "visible"
        }

        const { showMyCamLink, exhibitionistNotice, rightPanel } = this.rightPanel()

        this.listenerGroup = new ListenerGroup()

        window.setTimeout(() => {
            this.hasWaitAfterRoomLoadElapsed = true
        }, waitAfterRoomLoadMilliseconds)

        const createSendButton = function(text: string): HTMLSpanElement {
            const button = document.createElement("span")
            button.innerText = text
            button.title = text
            button.style.overflow = "hidden"
            button.style.lineHeight = "1.4"
            button.style.height = "24px"
            button.style.maxWidth = "76px"
            button.style.fontSize = "12px"
            button.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
            button.style.margin = "11px 0px 11px 0px"
            button.style.textOverflow = "ellipsis"
            button.style.whiteSpace = "nowrap"
            button.style.padding = "3px 10px 3px"
            button.style.boxSizing = "border-box"
            button.style.cursor = "pointer"
            button.style.display = "none"
            button.style.borderWidth = "1px"
            button.style.borderStyle = "solid"

            return button
        }

        this.sendTipButton = createSendButton(i18n.sendTipButtonCAPS)
        this.sendTipButton.id = "sendTipButton"
        this.sendTipButton.dataset.testid = "send-tip-button"
        addColorClass(this.sendTipButton, "sendTipButton")
        this.sendTipButton.style.marginRight = "4px"

        rightPanel.appendChild(this.sendTipButton)
        this.sendTipButton.onclick = () => {
            openDefaultTipCalloutRequest.fire({})
        }

        videoModeHandler.changeVideoMode.listen((videoModeChangeNotification: IVideoModeChangeNotification) => {
            this.bottomSection.element.style.display = "none"
            this.topSection.style.display = "block"
            this.appPanelHolder.style.display = "none"
            this.tipSection.style.display = "none"

            const mode = videoModeChangeNotification.currentMode
            switch (mode) {
                case VideoMode.Split:
                    this.bottomSection.element.style.display = this.bottomSectionDisplay
                    this.appPanelHolder.style.display = "inline-block"
                    this.tipSection.style.display = "inline-block"
                    break
                case VideoMode.IFS:
                    this.topSection.style.display = "none"
                    break
                case VideoMode.Fullscreen:
                    this.topSection.style.display = "none"
                    break
            }

            if (this.privateShowReqestPending && isPrivateShowRequestFeatureActive()) {
                if (mode === VideoMode.Split) {
                    privateShowSplitModeRequest.fire(undefined)
                } else {
                    privateShowRequestOverlayDismiss.fire(undefined)
                    if (this.draggableCanvas.privateWindow === undefined) {
                        privateWindowRequest.fire(undefined)
                    }
                }
            }

            this.updateAppPanel()
        })

        resizeDebounceEvent.listen(() => {
            if (multiAppPanel && videoModeHandler.getVideoMode() === VideoMode.Split) {
                return
            }
            this.updateAppPanel()
        })

        roomCleanup.listen(() => {
            this.listenerGroup.removeAll()
            if (isRoomRoomlistSpaActive()) {
                this.hideSubjectContainerContents()
            }
        })

        roomLoaded.listen((context) => {
            if (isRoomRoomlistSpaActive()) {
                this.showSubjectContainerContents()
            }
            this.updateSubject(context, abuseLinkInstance, roomSubjectInstance)

            const balance = isNaN(context.dossier.tokenBalance) ? 0 : context.dossier.tokenBalance
            this.tokenBalance.innerText = `${balance}`
            this.tokenBalanceSuffix.innerText = ` ${i18n.tokenOrTokensText(balance, false)}`

            this.isAgeVerified = context.dossier.isAgeVerified
            this.isBroadcaster = context.dossier.viewerUid === context.dossier.roomUid
            let allowPrivateShow = context.dossier.allowPrivateShow

            if (isPrivateShowRequestFeatureActive()) {
                resetPrivateShowLink.listen(() => {
                    // Fired from messagehandlers.ts when broadcaster changes private show price
                    // If user in private show, do nothing
                    if (this.privateShowActive) {
                        return
                    }
                    if (this.privateShowReqestPending) {
                        leavePrivateOrSpyShowAlertChain(context.chatConnection, false, {})
                    }
                    privateShowRequestOverlayDismiss.fire(undefined)
                    this.resetPrivateShowLink(context.chatConnection, context.chatConnection.status)
                }).addTo(this.listenerGroup)
            }

            if (this.isAgeVerified) {
                this.sendTipButton.style.display = "inline-block"
                exhibitionistNotice.style.display = "none"

                context.chatConnection.event.settingsUpdate.listen(notification => {
                    allowPrivateShow = notification.allowPrivateShow
                    if  (!notification.allowPrivateShow) {
                        privateShowRequestOverlayDismiss.fire(undefined)
                    }
                    this.updatePrivateState(allowPrivateShow, context.chatConnection, context.chatConnection.status)
                })

                context.chatConnection.event.statusChange.listen(status => { // eslint-disable-line complexity
                    const applyUpdates = () => {
                        this.updatePrivateState(allowPrivateShow, context.chatConnection, status.currentStatus)
                    }
                    const inSync = areRoomsInSync(context, status)
                    inSync ? applyUpdates() : syncRooms(context, status).then(applyUpdates).catch(ignoreCatch)
                    if (isPrivateShowRequestFeatureActive()) {
                        this.handlePrivateShowRequestTooltip(status)
                    }
                })
            } else {
                this.sendTipButton.style.display = "none"
                this.updatePrivateState(false, context.chatConnection, context.dossier.roomStatus)
                exhibitionistNotice.style.display = "block"
                exhibitionistNotice.style.position = "absolute"
                exhibitionistNotice.style.top = "54px"
            }

            this.setupListeners(allowPrivateShow, context, roomSubjectInstance)

            this.hasWaitAfterRoomLoadElapsed = false
            window.setTimeout(() => {
                this.hasWaitAfterRoomLoadElapsed = true
            }, waitAfterRoomLoadMilliseconds)
            if (context.dossier.roomStatus === RoomStatus.Offline) {
                showMyCamLink.style.display = "none"
            } else {
                showMyCamLink.style.display = "block"
            }
        })

        tokenBalanceUpdate.listen(delta => {
            this.tokenBalance.innerText = `${delta.tokens}`
            this.tokenBalanceSuffix.innerText = ` ${i18n.tokenOrTokensText(delta.tokens, false)}`
        })
    }

    private getAppPanel(): AppPanel | AppPanelCarousel {
        return multiAppPanel ? new AppPanelCarousel() : this.draggableCanvas.chatWindow.chatTabContainer.chatTab.appPanelChat.appPanel
    }

    private rightPanel() {
        const rightPanel = document.createElement("div")
        rightPanel.style.boxSizing = "border-box"
        rightPanel.style.verticalAlign = "top"
        rightPanel.style.display = "inline-block"
        rightPanel.style.verticalAlign = "top"

        rightPanel.style.padding = "2px 10px"
        this.tipSection.appendChild(rightPanel)

        const currentBalance = document.createElement("div")
        addColorClass(currentBalance, "currentBalance")
        currentBalance.style.display = "block"
        currentBalance.style.height = "19px"
        rightPanel.appendChild(currentBalance)

        const tokenBalancePrefix = document.createElement("span")
        tokenBalancePrefix.innerText = i18n.currentHaveText
        currentBalance.appendChild(tokenBalancePrefix)

        this.tokenBalance = document.createElement("span")
        this.tokenBalance.innerText = "0"
        this.tokenBalance.style.fontSize = "15px"
        this.tokenBalance.style.fontFamily = "UbuntuBold, Helvetica, Arial, sans-serif"
        currentBalance.appendChild(this.tokenBalance)

        this.tokenBalanceSuffix = document.createElement("span")
        this.tokenBalanceSuffix.innerText = " tokens"
        currentBalance.appendChild(this.tokenBalanceSuffix)

        const rightPanelSection = document.createElement("div")
        rightPanelSection.style.display = "inline-block"
        rightPanelSection.style.verticalAlign = "top"
        rightPanelSection.style.width = "130px"
        rightPanelSection.style.textOverflow = "ellipsis"
        rightPanelSection.style.overflow = "hidden"
        rightPanelSection.style.whiteSpace = "nowrap"
        rightPanel.appendChild(rightPanelSection)

        const promoteThisRoom = new PromoteRoomLink({})
        rightPanelSection.appendChild(promoteThisRoom.element)

        const showMyCamDiv = document.createElement("div")
        const showMyCamLink = this.createPanelLink(i18n.showMyCamShow)
        showMyCamLink.dataset.testid = "cam-to-cam-button"
        if (setupShowMyCamLink(showMyCamLink, showMyCamDiv)) {
            showMyCamDiv.appendChild(showMyCamLink)
            rightPanelSection.appendChild(showMyCamDiv)
        }


        this.privateShowLink = this.createPanelLink(i18n.privateShowStartLabel)
        this.privateShowLink.dataset.testid = "private-show-control-link"
        this.privateShowLink.style.display = "none"
        if (featureFlagIsActive("PremPrivShow")) {
            this.spyDisabledWarning = createHoverText()
            this.spyDisabledWarning.style.bottom = "16px"
            this.spyDisabledWarning.innerText = i18n.disabledShowSpyWarning
            rightPanelSection.style.overflow = "visible"
            this.privateShowDiv = this.createHoverDiv()
            this.privateShowDiv.appendChild(this.spyDisabledWarning)
            this.privateShowDiv.appendChild(this.privateShowLink)
            addColorClass(this.privateShowLink, "panelLink")
            hoverEvent(this.privateShowDiv).listen((hovering) => {
                if (this.privateShowLink.classList.contains("disabled")) {
                    this.spyDisabledWarning.style.display = hovering ? "block" : "none"
                    this.spyDisabledWarning.style.opacity = hovering ? "1" : "0"
                } else {
                    this.spyDisabledWarning.style.display = "none"
                    this.spyDisabledWarning.style.opacity = "0"
                }
            })
            rightPanelSection.appendChild(this.privateShowDiv)
        } else {
            rightPanelSection.appendChild(this.privateShowLink)
        }

        const exhibitionistNotice = document.createElement("div")
        exhibitionistNotice.innerText = i18n.broadcasterDoesNotAcceptTips
        exhibitionistNotice.style.display = "none"
        rightPanel.appendChild(exhibitionistNotice)

        return { showMyCamLink, exhibitionistNotice, rightPanel }
    }

    private updateSubject(context: IRoomContext, abuseLinkInstance: ReactComponent, roomSubjectInstance: ReactComponent) {
        abuseLinkInstance.update({ "roomName": context.dossier.room })
        roomSubjectInstance.update({ "text": context.dossier.roomTitle })
    }

    private setupListeners(allowPrivateShow: boolean, context: IRoomContext, roomSubjectInstance: ReactComponent) {
        switchedToHLS.listen(() => {
            this.updatePrivateState(allowPrivateShow, context.chatConnection, context.dossier.roomStatus)
        }).addTo(this.listenerGroup)

        context.chatConnection.event.titleChange.listen((newTitle: string) => {
            roomSubjectInstance.update({ "text": newTitle })
        }).addTo(this.listenerGroup)
    }

    updateAppPanel(): void {
        if (videoModeHandler.getVideoMode() === VideoMode.Split) {
            this.appPanel.element.style.padding = "0"
            this.appPanel.panelUpdated.fire(undefined)
        } else {
            while (this.appPanelHolder.firstChild !== null) {
                this.appPanelHolder.removeChild(this.appPanelHolder.firstChild)
            }
            this.appPanel.element.style.padding = "3px 5px"
            if (this.hasWaitAfterRoomLoadElapsed) {
                this.draggableCanvas.chatWindow.chatTabContainer.chatTab.appPanelChat.updatePanel()
            } else {
                window.setTimeout(() => {
                    this.draggableCanvas.chatWindow.chatTabContainer.chatTab.appPanelChat.updatePanel()
                }, waitAfterRoomLoadMilliseconds)
            }
        }
        this.appPanelHolder.style.maxWidth = multiAppPanel ? "320px" : `${appPanelWidth}px`
    }

    private createPanelLink(text: string): HTMLAnchorElement {
        const link = document.createElement("a")
        addColorClass(link, "panelLink")
        link.innerText = text
        link.title = text
        return link
    }

    private resetPrivateShowLink(chatConnection: IChatConnection, status: RoomStatus): void {
        this.updatePrivateShowLinkText(i18n.privateShowStartLabel)
        this.privateShowLink.style.display = "block"
        this.privateShowLink.style.maxWidth = "130px"
        this.privateShowLink.onclick = () => {
            enterPrivateShowAlertChain(chatConnection)
        }
    }

    private handlePrivateShowRequestTooltip(status: IRoomStatusChangeNotification): void { // eslint-disable-line complexity
        switch(status.currentStatus) {
            case RoomStatus.PrivateNotWatching:
            case RoomStatus.PrivateSpying:
            case RoomStatus.Offline:
            case RoomStatus.Away:
            case RoomStatus.Hidden:
            case RoomStatus.HiddenWatching:
            case RoomStatus.NotConnected:
            case RoomStatus.PasswordProtected:
            case RoomStatus.Public:
                this.privateShowActive = false
                this.privateShowReqestPending = false
                privateShowRequestOverlayDismiss.fire(undefined)
                break
            case RoomStatus.PrivateRequesting:
                this.privateShowActive = false
                this.privateShowReqestPending = true
                if (videoModeHandler.getVideoMode() === VideoMode.Theater) {
                    // If request is still pending and video mode switch to theater,
                    // we should dismiss the request overlay & summon private show request overlay in theater mode
                    privateShowRequestOverlayDismiss.fire(undefined)
                    if (this.draggableCanvas.privateWindow === undefined) {
                        privateWindowRequest.fire(undefined)
                    }
                } else if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                    // If request is still pending and video mode switch to split,
                    // we should summon private show request tooltip in requesting state
                    privateShowSplitModeRequest.fire(undefined)
                }
                break
            case RoomStatus.PrivateWatching:
                this.privateShowReqestPending = false
                this.privateShowActive = true
                break
            default:
                break
        }
    }

    // eslint-disable-next-line complexity
    private updatePrivateState(allowPrivates: boolean, chatConnection: IChatConnection, status: RoomStatus): void {
        this.updatePrivateShowLinkText(i18n.privateShowStartLabel)
        const privateStatuses = [RoomStatus.PrivateRequesting, RoomStatus.PrivateWatching, RoomStatus.PrivateSpying]
        // Broadcaster should not be able to see request/decline private show button while not on broadcast page
        if (!allowPrivates && privateStatuses.indexOf(status) === -1 || this.isBroadcaster) {
            this.privateShowLink.style.display = "none"
            return
        } else if (featureFlagIsActive("PremPrivShow") && chatConnection.premiumShowActive && status === RoomStatus.PrivateNotWatching) {
            // disable spy link for private shows
            this.privateShowLink.style.display = "block"
            this.spyDisabledWarning.innerText = i18n.premiumShowDesc
            this.privateShowLink.style.pointerEvents = "none"
            addColorClass(this.privateShowLink, "disabled")
            this.updatePrivateShowLinkText(i18n.privateShowSpyLabel)
            this.privateShowLink.onclick = () => {}
            return
        }

        this.privateShowLink.style.display = "block"
        if (featureFlagIsActive("PremPrivShow")) {
            removeColorClass(this.privateShowLink, "disabled")
            this.privateShowLink.style.opacity = "1"
            this.privateShowLink.style.pointerEvents = "auto"
            if (this.privateHoverEvent !== undefined) {
                this.privateHoverEvent = undefined
            }
            this.spyDisabledWarning.innerText = i18n.disabledShowSpyWarning
        }
        this.privateShowLink.onclick = () => {
            enterPrivateShowAlertChain(chatConnection)
        }

        const leavePrivateOrSpyOnClick = (event: MouseEvent) => {
            event.preventDefault()
            if (!this.leavePrivateOrSpyPending) {
                this.leavePrivateOrSpyPending = true
                if (chatConnection.privateMinEnd > Date.now()) {
                    leavePrivateOrSpyShowAlertChain(
                        chatConnection,
                        false,
                        { onFinally: () => {this.leavePrivateOrSpyPending = false} })
                } else {
                    modalConfirm(i18n.areYouSure, () => {
                        leavePrivateOrSpyShowAlertChain(
                            chatConnection,
                            false,
                            { onFinally: () => {this.leavePrivateOrSpyPending = false} })
                    }, () => {this.leavePrivateOrSpyPending = false})
                }
            }
        }

        if (isPrivateShowRequestFeatureActive()) {
            this.updatePrivateShowBadge(status)
        }

        switch (status) {
            case RoomStatus.PrivateRequesting:
                this.updatePrivateShowLinkText(i18n.privateShowRequestCancelMessage)
                this.privateShowLink.style.maxWidth = "130px"
                this.privateShowLink.onclick = leavePrivateOrSpyOnClick
                break
            case RoomStatus.PrivateNotWatching:
                this.updatePrivateShowLinkText(i18n.privateShowSpyLabel)
                this.privateShowLink.style.maxWidth = "130px"
                this.privateShowLink.onclick = () => {
                    enterSpyShowAlertChain(chatConnection)
                }
                break
            case RoomStatus.PrivateWatching:
            case RoomStatus.PrivateSpying:
                this.updatePrivateShowLinkText(i18n.privateShowLeaveLabel)
                this.privateShowLink.style.maxWidth = "100px"
                this.privateShowLink.onclick = leavePrivateOrSpyOnClick
                break
            case RoomStatus.Offline:
            case RoomStatus.Away:
            case RoomStatus.Hidden:
            case RoomStatus.HiddenWatching:
            case RoomStatus.NotConnected:
                this.privateShowLink.style.display = "none"
                this.privateShowLink.style.maxWidth = "130px"
                break
            case RoomStatus.PasswordProtected:
            case RoomStatus.Public:
                break
            default:
                warn(`unexpected status for updatePrivateState: ${status}`)
        }
    }

    private updatePrivateShowLinkText(text: string): void {
        this.privateShowLink.innerText = text
        this.privateShowLink.title = text
    }

    private updatePrivateShowBadge(status: RoomStatus): void {
        switch (status) {
            case RoomStatus.PrivateWatching:
                const dossier = roomDossierContext.getState()
                this.roomSubject.update({ "privateShowBroadcasting": true, "roomName": dossier.room })
                break
            default:
                this.roomSubject.update({ "privateShowBroadcasting": false })
                break
        }
    }

    private createHoverDiv(): HTMLDivElement {
        const div = document.createElement("div")
        applyStyles(div, {
            display: "inline-flex",
            position: "relative",
            alignItems: "center",
            justifyContent: "left",
            overflow: "visible",
        })
        return div
    }
    
    private hideSubjectContainerContents(): void {
        this.topSection.style.visibility = "hidden"
    }

    private showSubjectContainerContents(): void {
        this.topSection.style.visibility = ""
    }
}
