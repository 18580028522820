import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "./api"
import { roomLoaded } from "./context"
import { userCategoryToAbbrev } from "./genders"
import { oneAtATimePromise } from "./promiseUtils"
import type { IRoomContext } from "./context"
import type { BoundListener } from "./events"


export class NextRoomIterator {
    private seenRooms = new Set()
    private currentRoomName: string
    private currentRoomGender: string
    private listener: BoundListener<IRoomContext>

    constructor() {
        this.listener = roomLoaded.listen(context => {
            this.currentRoomName = context.chatConnection.room()
            this.currentRoomGender = userCategoryToAbbrev(context.dossier.roomGender)
            this.seenRooms.add(this.currentRoomName)
        }, false, true)
    }

    public next = oneAtATimePromise<string | undefined>(async () => {
        const queryParams = new URLSearchParams(window.location.search)
        queryParams.set("genders", this.currentRoomGender)
        queryParams.set("after", this.currentRoomName)
        const xhr = await getCb(`api/ts/roomlist/next-room/?${queryParams.toString()}`)
        const result = new ArgJSONMap(xhr.responseText)
        const rooms = JSON.parse(result.getObjectString("rooms"))
        const recommendation = this.roomFromRooms(rooms)
        if (recommendation !== undefined) {
            return recommendation
        }
        if (rooms.length > 0) {
            this.resetSeenRooms()
            return rooms[rooms.length - 1]
        }
        return undefined
    }, undefined)

    public dispose(): void {
        this.listener.removeListener()
    }

    private resetSeenRooms() {
        this.seenRooms = new Set()
        this.seenRooms.add(this.currentRoomName)
    }

    private roomFromRooms(rooms: string[]): string | undefined {
        for (const room of rooms) {
            if (!this.seenRooms.has(room)) {
                this.seenRooms.add(room)
                return room
            }
        }
        return undefined
    }
}
