import { addColorClass } from "../cb/colorClasses"
import { pageContext } from "../cb/interfaces/context"
import { normalizeResource } from "./api"
import { roomLoaded } from "./context"
import { RoomTabButton } from "./RoomTabButton"
import { i18n } from "./translation"
import type { IRoomContext } from "./context"

export class FanclubButton extends RoomTabButton {
    constructor() {
        super()
        addColorClass(this.element, "fanclubButton")
        this.textContainer.innerText = i18n.joinFanClub
        this.element.dataset.testid = "fanclub-button"

        roomLoaded.listen((context: IRoomContext) => {
            const addListeners = () => {
                this.element.onmouseover = () => {
                    this.textContainer.style.textDecoration = "underline"
                    this.element.style.cursor = "pointer"
                }

                this.element.onmouseleave = () => {
                    this.textContainer.style.textDecoration = ""
                }

                this.element.onmousedown = () => {
                    this.textContainer.style.textDecoration = ""
                }
            }

            if (context.dossier.paidTokensFanClub || !context.dossier.isInFanClub && context.dossier.hasFanClub) {
                this.element.onclick = () => {
                    const params = new URLSearchParams({ source: pageContext.current?.PurchaseEventSources["SUPPORTER_SOURCE_JOIN_FAN_CLUB_BUTTON"] })
                    if (!pageContext.current.loggedInUser) {
                        params.set("refresh_opener", "1")
                    }
                    window.open(normalizeResource(`/fanclub/join/${this.roomName}/?${params.toString()}`), "_blank")
                }
            }

            if (context.dossier.isInFanClub) {
                this.textContainer.innerText = i18n.member
                this.textContainer.style.textTransform = "uppercase"
                this.element.style.cursor = "default"
                if (context.dossier.paidTokensFanClub) {
                    addListeners()
                }
            } else {
                this.textContainer.innerText = i18n.joinFanClub
                this.textContainer.style.textTransform = "uppercase"
                this.element.style.cursor = "pointer"
                addListeners()
            }

            if (context.dossier.hasFanClub && !pageContext.current.isNoninteractiveUser) {
                this.showElement()
            } else {
                this.hideElement()
            }
        })
    }
}
