import { getScrollingDocumentElement } from "@multimediallc/web-utils/modernizr"
import { addColorClass, removeColorClass } from "../../cb/colorClasses"
import { buildTooltip } from "../../cb/ui/tooltip"
import { addEventListenerPoly, removeEventListenerPoly } from "../addEventListenerPolyfill"
import { postCb } from "../api"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { createDivotBottom, createDivotTop, DivotPosition } from "../divot"
import { RoomStatus } from "../roomStatus"
import { SatisfactionScore } from "../satisfactionScore"
import { i18n } from "../translation"
import type { IRoomContext } from "../context"

const minTokensToVote = 25

export class TheatermodeSatisfactionScore extends SatisfactionScore {
    private toolTip: HTMLDivElement
    private comment: SatisfactionComment | undefined
    private toolTipDisabled = false
    private label: HTMLSpanElement | undefined

    constructor(tabBar: HTMLDivElement) {
        super()

        this.element.id = "satisfactionScore"
        this.element.dataset.testid = "satisfaction-score-section"
        this.element.style.width = "auto"
        this.element.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        this.element.style.fontSize = "12px"
        this.element.style.padding = "4px 10px"
        this.element.style.position = "relative"
        this.element.style.top = "-4px"
        this.element.style.right = "1px"
        this.element.style.cssFloat = "right"
        this.element.style.borderRadius = "4px"
        this.element.style.cursor = "pointer"
        this.element.style.marginRight = "5px"

        this.element.onmouseenter = () => {
            if (!this.toolTipDisabled) {
                showToolTip()
            }
        }
        this.element.onmouseleave = () => {
            this.toolTip.style.display = "none"
        }

        this.buildToolTip()
        tabBar.appendChild(this.toolTip)

        const showToolTip = () => {
            this.toolTip.style.display = "block"
            const rect = this.element.getBoundingClientRect()
            this.toolTip.style.top = `${tabBar.offsetHeight + 8}px`
            this.toolTip.style.left = `${rect.left + getScrollingDocumentElement().scrollLeft + (rect.width - this.toolTip.offsetWidth) / 2}px`
        }

        roomLoaded.listen((context) => {
            if (this.comment !== undefined) {
                this.comment.hideImmediate()
                tabBar.removeChild(this.comment.element)
            }
            this.comment = new SatisfactionComment(context, this.element, () => {
                this.toolTipDisabled = false
            })
            tabBar.appendChild(this.comment.element)
            this.setToolTip(i18n.allowToVoteMessage(context.dossier.room, minTokensToVote))

            // create label only for staff users when satisfaction score is hidden
            if (context.dossier.hideSatisfactionScore && context.dossier.staffInfo !== undefined) {
                // Prevent creating this element twice.
                if (this.label === undefined) {
                    this.label = document.createElement("span")
                }
                // But update text/etc
                addColorClass(this.label, "satisfactionLabel")
                this.label.textContent = i18n.hiddenStaffOnlyTextCAPS
                this.label.style.margin = "0 3px"
                this.label.style.verticalAlign = "top"
                this.innerDiv.prepend(this.label)
            }
        })

        this.disableVoting()
    }

    repositionChildren(): void {
        if (this.comment !== undefined) {
            this.comment.repositionChildren()
        }
    }

    private buildToolTip(): void {
        this.toolTip = buildTooltip({
            content: "",
            hasHTML: false,
            width: 220,
            divotPosition: DivotPosition.Top,
            divotLeftOrTop: "123px",
            borderWidth: 1,
        })
        this.toolTip.style.lineHeight = "22px"
        this.toolTip.style.fontSize = "14px"
        this.toolTip.style.padding = "8px 0px 8px 8px"
        const span = document.createElement("span")
        this.toolTip.appendChild(span)
    }

    private setToolTip(s: string): void {
        const span = this.toolTip.lastChild as HTMLSpanElement
        span.innerText = s
    }

    protected triggerComment(reset: boolean): void {
        this.toolTipDisabled = true
        if (this.comment === undefined) {
            return
        }
        this.comment.show(reset)
        this.toolTip.style.display = "none"
    }

    protected enableVoting(): void {
        removeColorClass(this.element, "disabled")
        addColorClass(this.element, "enabled")
        super.enableVoting()
    }

    protected disableVoting(): void {
        removeColorClass(this.element, "enabled")
        addColorClass(this.element, "disabled")
        super.disableVoting()
    }
}

class SatisfactionComment extends Component {
    private hideTimeout: number
    private commentSection = document.createElement("div")
    private label = document.createElement("label")
    private textBox = document.createElement("textarea")
    private textLimit = document.createElement("span")
    private submitBtn = document.createElement("input")
    private roomName = ""
    private preventHide = false
    private hide: () => void
    private hidden = true
    private bottomDivot: HTMLDivElement
    private topDivot: HTMLDivElement
    private useOnlineRoomStyle = false

    constructor(context: IRoomContext, private satisfactionDiv: HTMLElement, private onHide: () => void) {
        super()
        this.roomName = context.dossier.room

        this.element.id = "satisfactionComment"
        this.element.dataset.testid = "satisfaction-comment"
        this.element.style.display = "none"
        this.element.style.width = "350px"
        this.element.style.height = "fit-content"
        this.element.style.borderWidth = "2px"
        this.element.style.borderStyle = "solid"
        this.element.style.borderRadius = "4px"
        this.element.style.bottom = "0px"
        this.element.style.right = "0px"
        this.element.style.overflow = ""
        this.element.style.zIndex = "11"
        this.element.onclick = (ev: MouseEvent) => {
            ev.stopPropagation()
        }

        this.bottomDivot = createDivotBottom("", "", "176px")
        addColorClass(this.bottomDivot, "divot")
        this.element.appendChild(this.bottomDivot)

        this.topDivot = createDivotTop("", "#e0e0e0", "176px")
        addColorClass(this.topDivot, "divot")
        this.element.appendChild(this.topDivot)

        const header = document.createElement("div")
        addColorClass(header, "header")
        header.innerText = i18n.submitYourFeedbackText
        header.style.boxSizing = "border-box"
        header.style.width = "100%"
        header.style.fontSize = "15px"
        header.style.fontWeight = "bold"
        header.style.padding = "6px"
        header.style.borderRadius = "4px 4px 0px 0px"
        this.element.appendChild(header)

        const message = document.createElement("div")
        message.style.padding = "6px"
        message.style.fontSize = "13px"
        message.innerText = i18n.confidentalVoteMessage(context.dossier.room)
        this.element.appendChild(message)

        this.commentSection.dataset.testid = "comment-section"
        this.element.appendChild(this.commentSection)

        this.styleCommentSection()

        this.hide = () => {
            this.hideImmediate()
        }

        this.element.onmousemove = () => {
            this.delayHide(10000)
        }

        this.useOnlineRoomStyle = context.dossier.roomStatus !== RoomStatus.Offline
    }

    show(reset: boolean): void {
        if (this.hidden) {
            addEventListenerPoly("click", document, this.hide)
            this.hidden = false
        }
        this.element.style.display = "block"
        this.buildCommentSection(reset)
        this.repositionChildren()
        if (this.textBox.parentNode !== null) {
            this.textBox.focus()
        }
        this.delayHide(10000)
    }

    repositionChildren(): void {
        if (this.element.parentNode !== null) {
            const rect = this.satisfactionDiv.getBoundingClientRect()
            this.element.style.left = `${rect.left + getScrollingDocumentElement().scrollLeft + (rect.width - this.element.offsetWidth) / 2 - 40}px`

            if (this.useOnlineRoomStyle) {
                this.element.style.bottom = "35px"
            } else {
                this.element.style.bottom = "unset"
            }
            this.switchDivotDisplay()
        }
    }

    /**
     * Switch the divot display from
     * - bottom to top when room is offline
     * - top to bottom when room is online
     */
    switchDivotDisplay(): void {
        if (this.useOnlineRoomStyle) {
            this.bottomDivot.style.display = "block"
            this.topDivot.style.display = "none"
        } else {
            this.bottomDivot.style.display = "none"
            this.topDivot.style.display = "block"
        }
    }

    private styleCommentSection(): void {
        this.label.style.fontSize = "13px"
        this.label.style.fontWeight = "bold"
        this.label.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        this.label.style.margin = "6px"
        this.label.innerText = i18n.optionalCommentNote

        addColorClass(this.textBox, "textBox")
        this.textBox.maxLength = 155
        this.textBox.style.width = "338px"
        this.textBox.style.resize = "none"
        this.textBox.style.fontSize = "13px"
        this.textBox.style.margin = "6px"
        this.textBox.style.padding = "4px"
        this.textBox.style.borderWidth = "1px"
        this.textBox.style.borderStyle = "solid"
        this.textBox.style.borderRadius = "4px"
        this.textBox.style.boxSizing = "border-box"
        this.textBox.style.display = "block"
        this.textBox.style.height = "81px"
        this.textBox.value = ""
        this.textBox.dataset.testid = "comment-text-box"

        this.textLimit.style.position = "absolute"
        this.textLimit.style.right = "10px"
        this.updateTextLimit()

        this.textBox.oninput = () => {
            this.preventHiding()
            this.updateTextLimit()
        }

        addColorClass(this.submitBtn, "submitBtn")
        this.submitBtn.setAttribute("type", "submit") // eslint-disable-line @multimediallc/no-set-attribute
        this.submitBtn.style.fontSize = "12px"
        this.submitBtn.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        this.submitBtn.style.padding = "5px 8px 4px"
        this.submitBtn.style.marginRight = "6px"
        this.submitBtn.style.marginTop = "25px"
        this.submitBtn.style.marginBottom = "10px"
        this.submitBtn.style.borderRadius = "4px"
        this.submitBtn.style.boxSizing = "border-box"
        this.submitBtn.style.cursor = "pointer"
        this.submitBtn.style.display = "inline-block"
        this.submitBtn.style.cssFloat = "right"
        this.submitBtn.value = "SUBMIT"
        this.submitBtn.dataset.testid = "submit-button"
        this.submitBtn.onclick = (ev: MouseEvent) => {
            ev.stopImmediatePropagation()
            this.submitComment(this.textBox.value)
        }
    }

    private buildCommentSection(reset: boolean): void {
        if (reset) {
            this.textBox.value = ""
            this.updateTextLimit()
            while (this.commentSection.firstChild !== null) {
                this.commentSection.removeChild(this.commentSection.firstChild)
            }
            this.commentSection.appendChild(this.label)
            this.commentSection.appendChild(this.textBox)
            this.commentSection.appendChild(this.textLimit)
            this.commentSection.appendChild(this.submitBtn)
        }
    }

    private submitComment(comment: string): void {
        postCb(`tipping/add_comment/${this.roomName}/`, { "comment": comment }).then(() => {
            while (this.commentSection.firstChild !== null) {
                this.commentSection.removeChild(this.commentSection.firstChild)
            }
            const submittedMsg  = document.createElement("div")
            addColorClass(submittedMsg, "submittedMsg")
            submittedMsg.style.width = "300px"
            submittedMsg.style.margin = "25px"
            submittedMsg.style.textAlign = "center"
            submittedMsg.style.fontSize = "13px"
            submittedMsg.innerText = i18n.commentSubmittedMessage
            this.commentSection.appendChild(submittedMsg)
            this.preventHide = false
            this.delayHide(5000)
        }).catch((err) => {
            error("Error rating model", err)
        })
    }

    private updateTextLimit(): void {
        this.textLimit.innerText = `${this.textBox.value.length}/${this.textBox.maxLength}`
    }

    private preventHiding(): void {
        clearTimeout(this.hideTimeout)
        this.preventHide = true
        this.hideTimeout = window.setTimeout(() => {
            this.preventHide = false
            this.delayHide(10000)
        }, 30000)
    }

    delayHide(timeout: number): void {
        if (this.preventHide) {
            return
        }
        clearTimeout(this.hideTimeout)
        this.hideTimeout = window.setTimeout(() => {
            this.hideImmediate()
        }, timeout)
    }

    hideImmediate(): void {
        if (this.hidden) {
            return
        }
        clearTimeout(this.hideTimeout)
        removeEventListenerPoly("click", document, this.hide)
        this.preventHide = false
        this.onHide()
        this.element.style.display = "none"
        this.hidden = true
    }
}
