import { ExpandableDropDownMenu } from "./expandableDropDownMenu"
import type { ToggleEvent } from "../../common/dropDownComponentBase"

export class ExpandAllDropDownMenu<T extends HTMLElement = HTMLElement> extends ExpandableDropDownMenu<T> {
    constructor(private collapseFn: () => boolean, element?: T, alignRight?: boolean) {
        super(element, alignRight)
    }

    protected separateOverflownSiblings(): void {
        // this may be called during super() constructor before this.collapseFn is assigned hence check for undefined
        if (this.collapseFn !== undefined && this.collapseFn()) {
            if (!this.isShown()) {
                this.overflownSibling = this.collapsibleSiblings()
                this.shownSibling = []
                this.addTabsToDropDown()
            }
        } else if (this.isShown()) {
            this.overflownSibling = []
            this.restoreTabsFromDropDown()
            this.shownSibling = this.collapsibleSiblings()
        }
    }

    onToggle(evt: ToggleEvent): void {
        if (evt.isShowing) {
            this.separateOverflownSiblings()
        }
    }

    protected reposition(): void {
        // this element position is not dynamic so no need to position it
    }

    protected fixLeftPosition(): void {
        // no need to fix left since there is only one element
    }
}
