import { resizeDebounceEvent } from "../../cb/ui/responsiveUtil"
import { roomLoaded } from "../context"
import { isShowingUserContextMenu } from "../userContextMenu"
import { VideoMode , videoModeHandler } from "../videoModeHandler"
import { emojiAdded } from "./emojiSelectionModal"
import type { DraggableCanvas } from "../fullvideolib/draggableCanvas"

// This class is responsible for switching between displaying full-featured ChatWindow (on DraggableCanvas)
//   and UI only PureChat
export class ChatPureModeHandler {
    private inputMode = false

    constructor(private draggableCanvas: DraggableCanvas) {
        this.inputMode = true

        this.draggableCanvas.focusChanged.listen(focusedWindow => {
            if (focusedWindow !== this.draggableCanvas.chatWindow) {
                this.maybeSetChatPureMode()
            } else {
                this.setChatInputMode()
            }
        })

        this.draggableCanvas.windowHover.listen(window => {
            if (window === this.draggableCanvas.chatWindow) {
                this.setChatInputMode()
            } else {
                this.maybeSetChatPureMode()
            }
        })

        // set as pure chat mode when load a new room from "Next Cam"
        roomLoaded.listen(() => {
            this.maybeSetChatPureMode()
        })

        emojiAdded.listen(() => {
            this.setChatInputMode()
        })

        resizeDebounceEvent.listen(() => {
            this.maybeSetChatPureMode()
        })

        videoModeHandler.changeVideoMode.listen((videoModeChangeNotificiation) => {
            this.maybeSetChatPureMode()
        })
    }

    private maybeSetChatPureMode(): void {
        const allowedVideoModes = [VideoMode.Theater, VideoMode.IFS, VideoMode.Fullvideo]
        if (!allowedVideoModes.includes(videoModeHandler.getVideoMode())) {
            return
        }
        if (!this.inputMode) {
            return
        }
        if (this.draggableCanvas.chatWindow.chatTabContainer.getCurrentTab() !== this.draggableCanvas.chatWindow.chatTabContainer.chatTab) {
            // don't show pure chat if not on the main chat
            return
        }
        if (this.draggableCanvas.focusedWindow === this.draggableCanvas.chatWindow ||
            this.draggableCanvas.hoveredWindow === this.draggableCanvas.chatWindow) {
            // don't show if chat is focused or hovered
            return
        }
        if (this.draggableCanvas.chatWindow.chatTabContainer.chatTab.chatTabContents.isScrolledUp()) {
            // do nothing if we are scrolled up
            return
        }
        if (isShowingUserContextMenu()) {
            // do nothing if user context menu is open
            return
        }
        this.inputMode = false
        this.draggableCanvas.chatWindow.setPureMode()
    }

    private setChatInputMode(): void {
        if (this.inputMode) {
            // short circuit if we're already on the proper state
            return
        }
        this.inputMode = true
        this.draggableCanvas.chatWindow.setInputMode()
    }
}
