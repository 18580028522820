import { addColorClass, colorClass } from "../../cb/colorClasses"
import { GameInfoBar } from "../../cb/components/games/gameInfoBar"
import { pageContext } from "../../cb/interfaces/context"
import { makeResponsive } from "../../cb/ui/responsiveUtil"
import { StaffLinks } from "../../cb/ui/staffLinks"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { BaseRoomContents } from "../baseRoomContents"
import { roomLoaded } from "../context"
import { numberFromStyle } from "../DOMutils"
import { getViewportHeight, getViewportWidth } from "../mobilelib/viewportDimension"
import { getDimensionsWithinBounds } from "../player/basePlayer"
import { sdRatio, wsRatio } from "../player/playerSettings"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { DismissibleMessages } from "./dismissibleMessages"
import { TheaterModeRoomTabs } from "./roomTabs"
import { VIDEO_CONTROLS_BAR_HEIGHT } from "./theaterVideoControls"
import type { TheaterModePlayer } from "./theaterModePlayer"
import type { IRoomContext } from "../context"
import type { DraggableCanvas } from "../fullvideolib/draggableCanvas"
import type { IVideoModeChangeNotification } from "../videoModeHandler"

export class TheaterModeRoomContents extends BaseRoomContents {
    constructor(player: TheaterModePlayer, draggableCanvas: DraggableCanvas) {
        super(player, draggableCanvas)

        this.element.style.width = ""

        const videoModeMargin = () => {
            return videoModeHandler.getVideoMode() === VideoMode.Split ? 12 : 8
        }
        makeResponsive(this, 500, 1003, [
            { name: "margin-left", min: 0, max: videoModeMargin },
            { name: "margin-right", min: 0, max: videoModeMargin },
        ])

        this.element.id = "TheaterModeRoomContents"

        const spacer = document.createElement("div")
        spacer.style.marginTop = "5px"
        this.element.appendChild(spacer)

        const staffLinksDiv = this.createStyledDiv(false)
        this.element.appendChild(staffLinksDiv)

        let staffLinks: StaffLinks | undefined
        roomLoaded.listen((context: IRoomContext) => {
            if (staffLinks !== undefined) {
                this.removeChild(staffLinks)
                staffLinks = undefined
            }
            if (context.dossier.staffInfo !== undefined && (pageContext.current.isInternal || !PRODUCTION)) {
                staffLinksDiv.style.display = "block"
                staffLinks = this.addChild(new StaffLinks(context.dossier.room, context.dossier.staffInfo), staffLinksDiv)
            } else {
                staffLinksDiv.style.display = "none"
            }
        })

        this.dismissibleMessages = this.addChild(new DismissibleMessages())
        this.gameInfoBar = new GameInfoBar()
        this.addChild(this.gameInfoBar)

        this.addChild(new TheaterModeRoomTabs())

        const defaultPlayer = () => {
            this.element.style.marginLeft = "12px"
            this.element.style.marginRight = "12px"
            this.player.playerComponent.element.style.top = "0"
            this.draggableCanvas.element.style.top = "0"
            this.draggableCanvas.element.style.visibility = "hidden"
            this.draggableCanvas.element.style.position = "fixed"
            this.chatTabContainer.element.style.display = "inline-block"
            this.resizeHandle.element.style.display = "inline-block"
            this.repositionChildrenRecursive()
        }

        const theaterPlayer = () => {
            this.element.style.marginLeft = "8px"
            this.element.style.marginRight = "8px"
            this.player.playerComponent.element.style.top = "0"
            this.draggableCanvas.element.style.top = "0"
            this.draggableCanvas.element.style.visibility = ""
            this.draggableCanvas.element.style.position = "absolute"
            this.chatTabContainer.element.style.display = "none"
            this.resizeHandle.element.style.display = "none"
            this.resizeHandle.handleResize(this.element.clientWidth)
            this.repositionChildrenRecursive()
        }

        const fullscreenPlayer = () => {
            const [playerWidth, playerHeight] = getDimensionsWithinBounds(getViewportWidth(), getViewportHeight(), this.player.getIsWidescreen())
            this.player.element.style.height = "100%"
            this.player.element.style.top = "0"
            this.player.element.style.left = "0"
            this.player.playerComponent.element.style.top = "0"
            this.player.playerComponent.element.style.left = "0"
            this.player.playerComponent.element.style.width = `${playerWidth}px`
            this.player.playerComponent.element.style.height = `${playerHeight}px`
            this.draggableCanvas.element.style.visibility = ""
            this.draggableCanvas.element.style.position = "absolute"
            this.draggableCanvas.element.style.top = "0px"
            this.draggableCanvas.element.style.left = "0"
            this.draggableCanvas.element.style.height = `${playerHeight - VIDEO_CONTROLS_BAR_HEIGHT}px`
            this.chatTabContainer.element.style.display = "none"
            this.resizeHandle.element.style.display = "none"
            this.repositionChildrenRecursive()
        }

        // needed for full window in safari
        const nativeFullscreenPlayer = () => {
            this.draggableCanvas.element.style.visibility = "hidden"
        }

        let first = true
        videoModeHandler.changeVideoMode.listen((videoModeChangeNotification: IVideoModeChangeNotification) => {
            if (this.emojiSelectionModal !== undefined && videoModeHandler.getVideoMode() !== VideoMode.Split) {
                this.emojiSelectionModal.hide()
            }
            if (first || videoModeHandler.getVideoMode() !== videoModeChangeNotification.previousMode) {
                switch (videoModeChangeNotification.currentMode) {
                    case VideoMode.Split:
                        defaultPlayer()
                        break
                    case VideoMode.Theater:
                        theaterPlayer()
                        break
                    case VideoMode.IFS:
                        fullscreenPlayer()
                        // timeout because IE doesn't update window.innerWidth before fullscreen event fires
                        window.setTimeout(() => {
                            if (videoModeHandler.getVideoMode() === VideoMode.IFS) {
                                fullscreenPlayer()
                            }
                        }, 500)
                        break
                    case VideoMode.Fullscreen:
                        nativeFullscreenPlayer()
                        break
                    default:
                        error(`Unexpected VideoMode: ${videoModeChangeNotification.currentMode}`)
                }
            }
            first = false
            // timeout because IE11 not giving correct dimensions on Fullscreen exit
            window.setTimeout(() => {
                this.repositionChildrenRecursive()
            }, 300)
        })

        // since entrance term over 18 sets the body overflow to hidden it will mess up the positioning
        // of theater mode initially. Here we reposition when the overflow hidden on body is removed.
        const closeEntranceButton = document.getElementById("close_entrance_terms")
        if (closeEntranceButton !== null) {
            addEventListenerPoly("click", closeEntranceButton, () => {
                this.repositionChildrenRecursive()
            })
        }

        this.player.videoControls.forceHlsPlayerEvent.listen(() => {
            this.repositionChildrenRecursive()
        })
    }

    private createStyledDiv(display = true): HTMLDivElement {
        const div = document.createElement("div")
        addColorClass(div, colorClass.defaultColor)
        addColorClass(div, "styledDiv")
        div.style.fontSize = "12px"
        div.style.padding = "3px"
        div.style.margin = "5px 0"
        div.style.borderRadius = "2px"
        div.style.lineHeight = "16px"
        div.style.display = display ? "" : "none"
        return div
    }

    protected repositionChildren(): void {
        if (videoModeHandler.getVideoMode() === VideoMode.Theater) {
            const { videoWidth, videoHeight } = this.calculateTheaterModeDimensions()
            this.draggableCanvas.element.style.height = `${videoHeight - VIDEO_CONTROLS_BAR_HEIGHT}px`

            this.player.element.style.height = `${videoHeight}px`
            this.player.setPlayerComponentDimensions(videoWidth, videoHeight)
            this.draggableCanvas.element.style.width = "100%"
        } else if (videoModeHandler.getVideoMode() === VideoMode.IFS) {
            this.draggableCanvas.element.style.width = "100%"
        }
        this.player.repositionChildrenRecursive()
        // Call this now because it sizes VideoPanel width and VideoPanel would repositionChildren before ResizeHandle does
        this.resizeHandle.handleResize()
    }

    public getAvailableWidth(): number {
        if (this.isShown()) {
            return super.getAvailableWidth()
        } else {
            return getViewportWidth() - this.roomContentsPadding * 2 -
                numberFromStyle(this.element.style.marginLeft) -
                numberFromStyle(this.element.style.marginRight)
        }
    }

    public calculateTheaterModeDimensions(): { videoWidth: number, videoHeight: number } {
        const theaterModeMinimumHeight = 270
        const videoBottomPadding = 118 // enough to see two rows of controls for changing video mode and send feedback
        const availableWidth = this.getAvailableWidth()
        const availableHeight = document.documentElement.clientHeight - videoBottomPadding
        let playerWidth: number
        let playerHeight: number
        const isWidescreen = this.player.getIsWidescreen()
        if (availableHeight <= theaterModeMinimumHeight) {
            playerHeight = theaterModeMinimumHeight
            playerWidth = playerHeight / (isWidescreen ? wsRatio : sdRatio)
        } else {
            [playerWidth, playerHeight] = getDimensionsWithinBounds(availableWidth,
                availableHeight - 27, isWidescreen)
            if (playerHeight <= theaterModeMinimumHeight) {
                playerHeight = theaterModeMinimumHeight
                playerWidth = playerHeight / (isWidescreen ? wsRatio : sdRatio)
            }
        }
        return { videoWidth: playerWidth, videoHeight: playerHeight }
    }
}
