import { PageType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { isRoomRoomlistSpaEligiblePage } from "../../cb/components/roomlist/spaHelpers"
import { RoomListSource, saveRoomListSourceCookie } from "../../cb/roomList"
import { CollapsibleComponent } from "../../cb/ui/expandableDropDownMenu"
import { ExpandAllDropDownMenu } from "../../cb/ui/expandAllDropDownMenu"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { recordInteractionIfAffiliatePage } from "../affiliates"
import { modalConfirm } from "../alerts"
import { normalizeResource } from "../api"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { applyStyles, underlineOnHover } from "../DOMutils"
import { loadRoomRequest } from "../fullvideolib/userActionEvents"
import { NextRoomIterator } from "../nextRoomIterator"
import { HlsNativePlayer } from "../player/hlsNativePlayer"
import { JpegPushPlayer } from "../player/jpegPlayer"
import { VideoJsPlayer } from "../player/videoJsPlayer"
import { i18n } from "../translation"
import { buildQueryString, parseQueryString } from "../urlUtil"
import { generateJoinOverlayAnchor } from "./joinOverlay"
import type { TheaterModePlayer } from "./theaterModePlayer"
import type { IRoomContext } from "../context"
import type { IRoomDossier } from "../roomDossier"

export class ScanNextCam extends Component {
    private scanningFrequency = 15000
    private countdownTimerIntervalID: number
    private isCamScanning = false
    private roomIterator = new NextRoomIterator()
    private idleTimer: number
    private scanningPaused = false
    private scanningTime: number
    private readonly scanCamLink: CollapsibleComponent<HTMLAnchorElement>
    private readonly scanCamText: HTMLSpanElement
    private readonly nextCamLink: CollapsibleComponent<HTMLAnchorElement>
    private currentDossier: IRoomDossier
    private readonly scanNextDropDownLink: ExpandAllDropDownMenu

    constructor(private genderTabs: { maxAvailableNonTabSpace: number }, private player?: TheaterModePlayer) {
        super()
        applyStyles(this, {
            zIndex: 1,
            height: "27px",
            width: "",
            top: isRoomRoomlistSpaEligiblePage() ? "2px" : "1px",
            textAlign: "right",
            position: "relative",
            cssFloat: "right",
            overflow: "visible",
            fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
        })

        const scanCamAnchor = generateJoinOverlayAnchor()
        this.scanCamLink = new CollapsibleComponent<HTMLAnchorElement>(scanCamAnchor)
        this.scanCamLink.onCollapseEvent.listen(collapsed => {
            if (collapsed) {
                this.scanCamLink.element.style.padding = "7px 3px 7px 0px"
                this.scanCamLink.element.style.right = "0"
            } else {
                this.scanCamLink.element.style.padding = "7px 3px 7px 5px"
                this.scanCamLink.element.style.right = "5px"
            }
        })
        this.scanCamLink.element.href = "#"
        this.scanCamLink.element.style.display = "inline-block"
        this.scanCamLink.element.style.padding = "7px 3px 7px 5px"
        this.scanCamLink.element.style.textDecoration = "none"
        this.scanCamLink.element.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        this.scanCamLink.element.style.fontSize = "10.008px"
        this.scanCamLink.element.style.position = "relative"
        this.scanCamLink.element.style.backgroundColor = "transparent"
        this.element.classList.add("scanNext")
        addColorClass(this.scanCamLink.element, colorClass.tabActiveColor)
        addColorClass(this.scanCamLink.element, "transparentBg")
        underlineOnHover(this.scanCamLink.element)
        this.scanCamLink.element.onclick = (event: MouseEvent) => {
            event.preventDefault()
            event.stopPropagation() // Prevent skip cam link from exiting scanning
            if (!this.isCamScanning) {
                if (isRoomRoomlistSpaEligiblePage()) {
                    UrlState.current.setPartialState({
                        pageType: PageType.ROOM,
                        room: this.currentDossier.room,
                        nextIn: this.scanningFrequency / 1000,
                    }, true)
                } else {
                    const currentParams = parseQueryString(window.location.search)
                    currentParams["next_in"] = (this.scanningFrequency / 1000).toString()
                    const builtQueryString = buildQueryString(currentParams)
                    window.history.replaceState({
                        "type": "room",
                        "room": this.currentDossier.room,
                    }, this.currentDossier.roomTitle, normalizeResource(`./?${builtQueryString}`))
                }
                this.startIdleTimeout()
            }
            this.nextRoom(true, true)
        }

        this.scanCamText = document.createElement("span")
        this.scanCamText.innerText = i18n.scanCamsText
        this.scanCamText.dataset.testid = "scan-cams"
        this.scanCamLink.element.appendChild(this.scanCamText)

        const nextCamAnchorEl = generateJoinOverlayAnchor()
        this.nextCamLink = new CollapsibleComponent<HTMLAnchorElement>(nextCamAnchorEl)
        this.nextCamLink.onCollapseEvent.listen(collapsed => {
            if (collapsed) {
                this.nextCamLink.element.style.padding = "6px 3px 6px 0px"
                this.nextCamLink.element.style.borderWidth = "0"
            } else {
                this.nextCamLink.element.style.padding = "6px 3px 6px 5px"
                this.nextCamLink.element.style.borderWidth = "1px"
            }
        })
        this.nextCamLink.element.href = "#"
        this.nextCamLink.element.style.display = "inline-block"
        this.nextCamLink.element.innerText = `${i18n.nextCamText} (Ctrl+/)`
        this.nextCamLink.element.dataset.testid = "next-cam"
        this.nextCamLink.element.style.textDecoration = "none"
        this.nextCamLink.element.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        this.nextCamLink.element.style.fontSize = "10.008px"
        this.nextCamLink.element.style.lineHeight = "normal"
        this.nextCamLink.element.style.position = "relative"
        addColorClass(this.nextCamLink.element, colorClass.nextCamBgColor)
        addColorClass(this.nextCamLink.element, colorClass.tabBorder)
        addColorClass(this.nextCamLink.element, colorClass.tabActiveColor)
        this.nextCamLink.element.style.borderWidth = "1px"
        this.nextCamLink.element.style.borderStyle = "solid"
        this.nextCamLink.element.style.borderBottom = "0"
        this.nextCamLink.element.style.borderRadius = "4px 4px 0 0"
        underlineOnHover(this.nextCamLink.element)
        this.nextCamLink.element.onclick = (event: MouseEvent) => {
            event.preventDefault()
            if (this.isCamScanning) {
                this.stopScanning()
            } else {
                this.nextRoom(false, true)
            }
        }

        this.element.dataset["paction"] = "NextCam"

        this.addChild(this.scanCamLink)
        this.addChild(this.nextCamLink)
        this.scanNextDropDownLink = new ExpandAllDropDownMenu(() => this.isTooCloseToGendersTab(),
            ScanNextCam.buildScanNextDropDownHandle(), true)
        this.addChild(this.scanNextDropDownLink)
        applyStyles(this.scanNextDropDownLink, {
            padding: "4px 6px",
            top: "1px",
        })
        applyStyles(this.scanNextDropDownLink.dropDown, {
            width: "127px",
            padding: "8px 12px",
        })

        addEventListenerPoly("keydown", document, (e: KeyboardEvent): void => {
            if (e.key === "Control" || e.key === "Meta" || e.key === "OS") {
                return
            }
            if ((e.key === "/" || e.which === 191) && (e.ctrlKey || e.metaKey)) { // ctrl+/ (forward slash)
                e.preventDefault()
                recordInteractionIfAffiliatePage()
                this.nextRoom(false, true)
            } else if (this.isCamScanning && !this.scanningPaused) {
                this.stopScanning()
            }
        }, true)

        roomLoaded.listen((context) => {
            this.init(context)
            this.currentDossier = context.dossier
            this.startNextInScanning()
        })

        const preventStopScan = (e: MouseEvent): boolean => {
            return isElementPartOfVideoJsControls(e.target) || isElementPartOfHlsNativeControls(e.target) ||
                isElementPartOfVideoControls(e.target) || isElementPartOfScanCamControls(e.target)
        }

        const isElementPartOfVideoJsControls = (target: EventTarget | null): boolean => {
            if (this.player === undefined) {
                return false
            }

            if (this.player.playerComponent instanceof VideoJsPlayer) {
                const vjsVolumePanel = this.player.element.getElementsByClassName("vjs-volume-panel")
                for (const element of vjsVolumePanel) {
                    if (target === element) {
                        return true
                    }
                    for (const ele of element.getElementsByTagNameNS("*", "*")) {
                        if (target === ele) {
                            return true
                        }
                    }
                }
            }
            return false
        }

        const isElementPartOfHlsNativeControls = (target: EventTarget | null): boolean => {
            if (this.player === undefined) {
                return false
            }
            if (this.player.playerComponent instanceof HlsNativePlayer) {
                return target === this.player.playerComponent.getVideoElement()
            }
            return false
        }

        const isElementPartOfVideoControls = (target: EventTarget | null): boolean => {
            if (this.player === undefined) {
                return false
            }
            return this.player.videoControls.checkIfElementIsInVolumeControls(target)
        }

        const isElementPartOfScanCamControls = (target: EventTarget | null): boolean => {
            return target === this.scanCamLink.element || target === this.scanCamText
        }

        const stopScanOnMouseDown = (e: MouseEvent): void => {
            if (this.scanningPaused || preventStopScan(e) || e.target === this.scanNextDropDownLink.element) {
                return
            }
            if (this.isCamScanning && e.target !== this.nextCamLink.element) {
                this.stopScanning()
            }
        }
        addEventListenerPoly("mousedown", document, stopScanOnMouseDown, false)
    }

    private startNextInScanning() {
        if (isRoomRoomlistSpaEligiblePage()) {
            if (UrlState.current.state.nextIn !== undefined) {
                this.scanningFrequency = UrlState.current.state.nextIn * 1000
                this.startScanning()
                this.repositionChildrenRecursive()
            }
        } else {
            const queryParams = parseQueryString(window.location.search)
            if (!("next_in" in queryParams)) {
                return
            }
            const scanFrequencyUnparsed = queryParams["next_in"]
            const scanFrequency: number = parseInt(scanFrequencyUnparsed as string)
            if (!isFinite(scanFrequency) || isNaN(scanFrequency)) {
                return
            }
            if (scanFrequency >= 1) {
                this.scanningFrequency = scanFrequency * 1000
            }
            this.startScanning()
            this.repositionChildrenRecursive()
        }
    }

    private init(context: IRoomContext): void {
        this.nextCamLink.element.href = normalizeResource(`/next/${context.dossier.room}/`)
        this.scanCamLink.element.href = normalizeResource(`/next/${context.dossier.room}/?next_in=15`)

        this.currentDossier = context.dossier
        this.startNextInScanning()
    }

    private isTooCloseToGendersTab(): boolean {
        if (this.parent !== undefined) {
            // subnavheadertabs doesn't include non tab space, while genderTabs includes the whole bar and scanNextCam
            return this.genderTabs.maxAvailableNonTabSpace - this.getUncollapsedWidth() < 20
        }
        return false
    }

    private uncollapsedWidth?: number // This property lives down here bc we should never access it directly, always go through getUncollapsedWidth
    private getUncollapsedWidth(): number {
        const currentWidth = this.scanCamLink.element.offsetWidth + this.nextCamLink.element.offsetWidth
        if (this.uncollapsedWidth === undefined && currentWidth > 0) {
            this.uncollapsedWidth = currentWidth
        }
        return this.uncollapsedWidth ?? 0
    }

    private static buildScanNextDropDownHandle(): HTMLDivElement {
        const scanNextDropDownDiv = document.createElement("div")
        scanNextDropDownDiv.style.display = "none"
        scanNextDropDownDiv.style.cursor = "pointer"
        scanNextDropDownDiv.style.padding = "7px 4px"
        scanNextDropDownDiv.innerText = i18n.scanNextText
        scanNextDropDownDiv.style.textDecoration = "none"
        scanNextDropDownDiv.style.fontSize = "10.008px"
        scanNextDropDownDiv.style.position = "relative"
        scanNextDropDownDiv.style.borderWidth = "1px"
        scanNextDropDownDiv.style.borderStyle = "solid"
        addColorClass(scanNextDropDownDiv, colorClass.nextCamBgColor)
        addColorClass(scanNextDropDownDiv, colorClass.tabBorder)
        addColorClass(scanNextDropDownDiv, colorClass.tabActiveColor)
        scanNextDropDownDiv.style.borderBottom = "0"
        scanNextDropDownDiv.style.borderRadius = "4px 4px 0 0"
        scanNextDropDownDiv.style.textAlign = "center"
        underlineOnHover(scanNextDropDownDiv)
        return scanNextDropDownDiv
    }

    private startIdleTimeout(): void {
        this.idleTimer = window.setTimeout(() => {
            this.pauseScanning()
            this.resetIdleTimeout()
        }, 3 * 60 * 60 * 1000)
    }

    private resetIdleTimeout(): void {
        modalConfirm("Are you still watching?", () => {
            this.startIdleTimeout()
            this.resumeScanning()
        }, () => {
            this.stopScanning()
            this.scanningTime = this.scanningFrequency
        })
    }

    private setupScanningControls(): void {
        debug("setting up scanning controls")
        this.scanningTime = this.scanningPaused ? this.scanningTime : this.scanningFrequency
        this.countdownTimerIntervalID = window.setInterval(() => {
            this.scanningTime -= 1000
            if (this.scanningTime / 1000 <= 0) {
                this.scanningTime = this.scanningFrequency
                this.nextRoom(true)
            }
            this.scanCamText.innerText = `${i18n.skipCamText} (${this.scanningTime / 1000}s)`
            this.scanNextDropDownLink.element.innerText = `${i18n.scanText} (${this.scanningTime / 1000}s)`
        }, 1000)
        this.scanCamText.innerText = `${i18n.skipCamText} (${this.scanningTime / 1000}s)`
        this.scanNextDropDownLink.element.innerText = `${i18n.scanText} (${this.scanningTime / 1000}s)`
        this.nextCamLink.element.innerText = `${i18n.exitScanningText} (any key)`
    }

    private teardownScanningControls(): void {
        debug("Destroying cam controls")
        clearInterval(this.countdownTimerIntervalID)
        this.scanCamText.innerText = i18n.scanCamsText
        this.nextCamLink.element.innerText = `${i18n.nextCamText} (Ctrl+/)`
        this.scanNextDropDownLink.element.innerText = i18n.scanNextText
    }

    private nextRoom(fromScan: boolean, userInputTriggered = false): void {
        debug("Switching rooms")
        this.roomIterator.next().then((room) => {
            if (room !== undefined) {
                const source = fromScan ? RoomListSource.ScanCam : RoomListSource.NextCam
                saveRoomListSourceCookie(room, source, 1)
                loadRoomRequest.fire(room)
            }
        }).catch((reason) => {
            error("Error calling roomIterator.next", reason)
            this.repositionChildrenRecursive()
        })
        if (this.player !== undefined && this.player.playerComponent instanceof JpegPushPlayer && userInputTriggered) {
            this.player.videoControls.maybeForceHls()
        }
    }

    private pauseScanning(): void {
        this.scanningPaused = true
        clearInterval(this.countdownTimerIntervalID)
        debug("pausing scanning")
    }

    private resumeScanning(): void {
        debug("resuming scanning")
        this.setupScanningControls()
        this.scanningPaused = false
    }

    private startScanning(): void {
        debug("Starting cam scan.")
        this.teardownScanningControls()
        this.setupScanningControls()
        this.isCamScanning = true
    }

    private stopScanning(): void {
        if (!this.isCamScanning) {
            error("Attempted to stop cam scanning when cams were not scanning")
            return
        }
        debug("Stopping cam scan.")
        clearTimeout(this.idleTimer)
        this.teardownScanningControls()
        this.isCamScanning = false
        this.scanningPaused = false
        const currentQueryString = parseQueryString(window.location.search)
        delete currentQueryString["next_in"]
        const queryString = buildQueryString(currentQueryString)
        let fullQueryString = ""
        if (queryString !== "") {
            fullQueryString = `?${queryString}`
        }
        if (isRoomRoomlistSpaEligiblePage()) {
            UrlState.current.replaceUrl(`/${this.currentDossier.room}/${fullQueryString}`)
        } else {
            window.history.replaceState({
                "type": "room",
                "room": this.currentDossier.room,
            }, this.currentDossier.roomTitle, normalizeResource(`./${fullQueryString}`))
        }
    }
}
