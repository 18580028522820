import { getCb, normalizeResource } from "../../common/api"
import { Component } from "../../common/defui/component"
import { addColorClass } from "../colorClasses"
import type { IAnchorInfo, IStaffInfo } from "../../common/roomDossier"

export class StaffLinks extends Component {
    private showingAdminInfo = false
    private fetchedAdminInfo = false
    private adminInfoSection: HTMLDivElement

    constructor(private room: string, staffInfo: IStaffInfo) {
        super()

        this.element.id = "StaffLinks"
        this.element.style.position = "static"
        this.element.style.height = "auto"

        const topSection = document.createElement("div")
        if (staffInfo.deletionAdminNotice !== undefined) {
            const deletionAdminNotice = document.createElement("div")
            deletionAdminNotice.id = "deletion_admin_notice"
            deletionAdminNotice.style.margin = "10px"
            deletionAdminNotice.innerHTML = staffInfo.deletionAdminNotice // eslint-disable-line @multimediallc/no-inner-html
            topSection.appendChild(deletionAdminNotice)
        }
        if (staffInfo.previousUsernames !== undefined) {
            const previousUsernamesSection = document.createElement("div")
            addColorClass(previousUsernamesSection, "prevUsernamesSection")
            previousUsernamesSection.style.padding = "5px"
            previousUsernamesSection.textContent = `Previously known as ${staffInfo.previousUsernames}`
            topSection.appendChild(previousUsernamesSection)
        }
        this.element.appendChild(topSection)

        const middleSection = document.createElement("div")
        this.element.appendChild(middleSection)

        const showHideAdmin = document.createElement("a")
        showHideAdmin.href = "#"
        addColorClass(showHideAdmin, "link")
        showHideAdmin.style.marginRight = "15px"
        showHideAdmin.textContent = "Show/Hide Admin Info"
        showHideAdmin.onclick = (ev) => {
            ev.preventDefault()
            this.toggleAdminInfo()
        }
        middleSection.appendChild(showHideAdmin)

        this.adminInfoSection = document.createElement("div")
        this.adminInfoSection.id = "admin-info-section"
        this.adminInfoSection.style.display = "none"
        this.adminInfoSection.textContent = "Loading admin information..."
        this.element.appendChild(this.adminInfoSection)

        for (let i = 0; i < staffInfo.staffLinks.length; i += 1) {
            const a = this.buildLink(staffInfo.staffLinks[i])
            middleSection.appendChild(a)
            if (i !== staffInfo.staffLinks.length - 1) {
                const bar = document.createElement("span")
                bar.style.margin = "0 2px"
                bar.textContent = "|"
                middleSection.appendChild(bar)
            }
        }

        if (staffInfo.autoLoadAdminInfo) {
            this.toggleAdminInfo()
        }
    }

    private buildLink(link: IAnchorInfo): HTMLAnchorElement {
        const a = document.createElement("a")
        a.href = normalizeResource(link.href)
        a.textContent = link.text
        addColorClass(a, "link")
        if (link.text === "View Stream Status") {
            a.onclick = (ev) => {
                ev.preventDefault()
                const popupWindow = window.open(normalizeResource(`/view_user_obs_overlay/${this.room}/?graph=1`), "obs_info",
                    "status=0,toolbar=0,menubar=0,directories=0,resizable=1,scrollbars=1,height=600,width=750")
                if (popupWindow !== null) {
                    popupWindow.focus()
                }
            }
        }
        return a
    }

    private toggleAdminInfo(): void {
        if (this.showingAdminInfo) {
            this.adminInfoSection.style.display = "none"
            this.showingAdminInfo = false
            return
        }

        if (!this.fetchedAdminInfo) {
            this.loadAdminInfo()
            this.fetchedAdminInfo = true
        }
        this.adminInfoSection.style.display = "block"
        this.showingAdminInfo = true
    }

    private loadAdminInfo(): void {
        getCb(`ax/room_info/${this.room}/`).then((data) => {
            // We need to evaluate the <script> tag included in data.responseText
            // because it includes event listeners that need to be applied
            // so please avoid using something like .innerHTML in here as it will not
            // evaluate HTML included in the response string.
            $(this.adminInfoSection)["html"](data.responseText)
        }).catch(() => {})
    }
}
