import { applyStyles } from "../../common/DOMutils"
import { ModalComponent } from "../../common/modalComponent"
import { addPageAction } from "../../common/newrelic"
import { SIGN_UP_BASE_PATH } from "../../common/redirectParamUtils"
import { i18n } from "../../common/translation"
import { dom } from "../../common/tsxrender/dom"
import { addColorClass } from "../colorClasses"
import { pageContext } from "../interfaces/context"

export class RoomSignupPopup extends ModalComponent {
    protected hideOverlay = true

    constructor() {
        super({ easyExit: false })

        const popopStyle: CSSX.Properties = {
            position: "fixed",
            right: "20px",
            bottom: "20px",
            width: "auto",
            height: "auto",
            zIndex: 1099,  // below all standard ModalComponents
            boxSizing: "border-box",
            filter: "drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.16))",
            borderRadius: "4px",
            WebkitOverflowScrolling: "touch",
        }

        const contentBodyStyle: CSSX.Properties = {
            width: pageContext.current.languageCode === "en" ? "300px" : "320px",
            height: "auto",
            marginRight: "10px",
        }

        const contentBodyTitleFontSize = 16
        const contentBodyTitleStyle: CSSX.Properties = {
            margin: "0 0 8px",
            font: `${contentBodyTitleFontSize}px 'UbuntuBold', Arial, Helvetica, sans-serif`,
            lineHeight: "24px",
        }

        const contentBodyTextStyle: CSSX.Properties = {
            margin: "0 0 16px",
            font: `${contentBodyTitleFontSize}px 'UbuntuRegular', Arial, Helvetica, sans-serif`,
            lineHeight: "24px",
        }

        const signupLinkStyle: CSSX.Properties = {
            cssFloat: "left",
            font: "14px 'UbuntuLight', Arial, Helvetica, sans-serif",
            lineHeight: "16px",
            textDecoration: "none",
            padding: "8px",
            border: "1px solid #cd5c26",
            borderRadius: "4px",
            boxSizing: "border-box",
        }

        const closeButtonStyle: CSSX.Properties = {
            background: `transparent url(${STATIC_URL}close-lightgray.svg) no-repeat center center`,
            backgroundSize: "12px",
            padding: "12px",
            border: "none",
            cursor: "pointer",
        }

        const closeDivStyle: CSSX.Properties = {
            position: "absolute",
            top: "0px",
            right: "0px",
            margin: "8px",
        }

        const logoImgStyle: CSSX.Properties = {
            position: "absolute",
            width: "195px",
            height: "175px",
            left: "161px",
            bottom: "-39px",
            background: `transparent url(${STATIC_URL}signup_popup_bg.svg) no-repeat`,
            zIndex: -1,
        }

        applyStyles(this.element, popopStyle)
        addColorClass(this.element, "RoomSignupPopup")
        const contentDiv =
            <div style={{ display: "block", margin: "16px 14px" }}>
                <div style={logoImgStyle}/>
                <div style={closeDivStyle}>
                    <button style={closeButtonStyle} type="submit"
                        onClick={(e: Event) => {
                            e.preventDefault()
                            this.hide()
                        }}
                    />
                </div>
                <div style={contentBodyStyle}>
                    <p style={contentBodyTitleStyle} className="title">
                        {i18n.joinChaturbateCommunity}
                    </p>
                    <p style={contentBodyTextStyle} className="body">
                        {i18n.roomSignupPopupText}
                    </p>
                    <div style={{ overflow: "hidden" }}>
                        <a href={SIGN_UP_BASE_PATH} type="button" style={signupLinkStyle} className="signupLink"
                            onClick={() => {
                                addPageAction("RoomPopupSignupClicked")
                            }}
                            account-redirect
                        >{i18n.roomSignupPopupSignupText}</a>
                    </div>
                </div>
            </div>
        this.element.appendChild(contentDiv)
    }
}

let signupPopup: RoomSignupPopup | undefined
let popupDelayId: number | undefined

export function showRoomSignupPopup(): void {
    if (signupPopup !== undefined) {
        signupPopup.hide()
    }
    if (popupDelayId !== undefined) {
        window.clearTimeout(popupDelayId)
    }
    popupDelayId = window.setTimeout(() => {
        if (signupPopup === undefined) {
            signupPopup = new RoomSignupPopup()
        }
        signupPopup.show()
    }, 5 * 60 * 1000) // 5 minutes
}
