// vim: set ts=4 sw=4 et fileencoding=utf-8:
import { roomLoaded } from "../../../common/context"
import { Component } from "../../../common/defui/component"
import { applyStyles } from "../../../common/DOMutils"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { currentSiteSettings } from "../../siteSettings"
import { GameSelection } from "./gameSelection"
import type { GameSelectionData } from "./gameSelection"

/**
 * Self managed component that displays the broadcaster's selected game
 *
 * This is used for both broadcasters and viewers.
 */
export class GameInfoBar extends Component {
    /**
     * Container for the inner display elements
     */
    private displayElement: HTMLElement
    private playerUsername = "User"

    constructor(private readonly isBroadcaster = false) {
        super()

        this.element.id = "gameInfoBar"
        applyStyles(this.element, {
            display: "none",
            borderRadius: "3px",
            boxSizing: "border-box",
            marginBottom: "6px",
            overflow: "visible",
            position: "static",
        })

        this.element.appendChild(this.displayElement = <div />) // placeholder

        GameSelection.selectionChange.listen((game: GameSelectionData) => {
            this.render(game)
        }, false)

        roomLoaded.listen((roomContext) => {
            this.playerUsername = roomContext.dossier.room
            this.getAndSetCurrentGameSelection(roomContext.chatConnection.room())  // eslint-disable-line @typescript-eslint/no-floating-promises
        })
    }

    /**
     * Re/renders the display element (for data changes & initial display)
     */
    private render(game: GameSelectionData): void {
        if (game !== undefined && game !== null) {
            const newNode = this.renderDisplayContent(game)
            this.element.replaceChild(newNode, this.displayElement)
            this.displayElement = newNode

            this.show()
        } else {
            this.hide()
        }
    }

    /**
     * Renders the main UI
     */
    private renderDisplayContent(game: GameSelectionData): HTMLDivElement {
        return (
            <div
                style={{
                    borderRadius: "3px",
                    overflow: "hidden",
                    padding: "6px",
                    position: "relative",
                    display: "flex",
                }}
                className="GameInfoBar"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                    className="gameImage"
                >
                    <img
                        src={
                            game?.image ??
                            `${STATIC_URL}images/obs_icon_small.png`
                        }
                        alt={game?.name ?? ""}
                        width="48"
                        height="48"
                        style={{
                            borderRadius: "5px",
                            marginRight: "8px",
                            objectFit: "cover",
                            overflow: "hidden",
                        }}
                    />
                </div>
                <div
                    style={{
                        height: "100%",
                        verticalAlign: "center",
                        paddingLeft: "8px",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            fontSize: "14px",
                            lineHeight: "14px",
                            fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
                            padding: "6px 0",
                        }}
                    >
                        {this.isBroadcaster ? "You are" : `${this.playerUsername} is`} playing{" "}
                        <a href={game?.gameUrl} target="_blank" rel="noopener">
                            {game?.name}
                        </a>
                    </div>
                    <div
                        style={{
                            paddingBottom: "6px",
                            width: "100%",
                        }}
                    >
                        <p
                            style={{
                                fontSize: "12px",
                                lineHeight: "12px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                margin: "0px",
                                width: "100%",
                            }}
                        >
                            {this.isBroadcaster ? 
                                <span>
                                    We added{" "}
                                    <a href="/tag/gaming/" target="_blank">
                                        #gaming
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href={`/tag/${encodeURI(
                                            nameTag(game?.name ?? ""),
                                        )}`}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        #{nameTag(game?.name ?? "")}
                                    </a>{" "}
                                    to your stream, but did not change your room
                                    subject.
                                </span>
                                : 
                                game?.description
                            }
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        width: "40%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end",
                    }}
                >
                    {this.isBroadcaster ? 
                        <div
                            style={{
                                borderRadius: "4px",
                                cursor: "pointer",
                                fontSize: "14px",
                                lineHeight: "16px",
                                marginRight: "6px",
                                padding: "6px",
                                right: "0",
                            }}
                            className="stopStreamingButton"
                            onClick={() => {
                                GameSelection.deselectGame()  // eslint-disable-line @typescript-eslint/no-floating-promises
                            }}
                        >
                            {i18n.stopStreamingGame}
                        </div>
                        :
                        <div
                            style={{
                                margin: "6px 0",
                                padding: "8px",
                                textAlign: "right",
                                boxSizing: "border-box",
                                width: "100%",
                            }}
                        >
                            {i18n.areYouAPublisher} <a href={currentSiteSettings.cbGamesUrl} target="_blank" rel="noopener noreferrer">{i18n.addYourGameLink}</a>
                        </div>
                    }
                </div>
            </div>
        )
    }

    private async getAndSetCurrentGameSelection(room: string): Promise<void> {
        try {
            this.render(await GameSelection.getCurrentGame(room))
        } catch (e) {}
    }

    public show(): void {
        this.element.style.display = ""
    }

    public hide(): void {
        this.element.style.display = "none"
    }
}

/**
 * Returns the smashed version of the game name
 *
 * e.g. "League of Legends™" -> "LeagueOfLegends"
 */
function nameTag(name: string): string {
    return name
        .split(/\s+/)
        .map((s: string) => {
            return s[0].toUpperCase() + s.substring(1)
        })
        .join("")
        .replace(/[^a-zA-Z\d]/g, "")
}
