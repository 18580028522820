import { t } from "@lingui/macro"
import { isLinguiBasicActive } from "../../../common/featureFlagUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { PromoteLinkBase } from "./promoteLinkBase"

export class PromoteRoomLink extends PromoteLinkBase {
    protected createElement(): HTMLAnchorElement {
        return <a bind={{ className: () => `panelLink promoteRoomLink ${this.state.isActive ? "" : "disabled"}` }}
            data-testid="promote-room-link"
            onClick={() => {this.clickHandler()}}
        >
            {isLinguiBasicActive() ? t`Promote This Room` : i18n.promoteThisRoom}
        </a>
    }
}
