import { normalizeResource } from "../common/api"

export function updateLoginOverlayNextElement(room: string): void {
    const loginForm = document.querySelector<HTMLFormElement>("#login_inputs form")
    if (loginForm !== null) {
        loginForm.action = normalizeResource(replaceUrlQueryParam(loginForm.action, "next", `/${room}/`))
    }
    const loginNextInput = document.querySelector<HTMLInputElement>("#login_inputs input[name=\"next\"]")
    if (loginNextInput !== null) {
        loginNextInput.value = `/${room}/`
    }
}

export function updateHeaderJoinLinkRoom(room: string): void {
    let joinLink = document.querySelector<HTMLAnchorElement>(".nav-bar .creat")
    if (joinLink === null) {
        joinLink = document.querySelector<HTMLAnchorElement>("#user_information .auip_creat")
    }
    if (joinLink !== null) {
        joinLink.href = normalizeResource(replaceUrlQueryParam(joinLink.href, "room", room))
    }
}

function replaceUrlQueryParam(url: string, key: string, newValue: string, addIfNotExists = true): string {
    const replaceStr = url.split(/\s*[?&]\s*/).reduce((prev, curr) => {
        if (curr.indexOf(`${key}=`) === 0) {
            return curr
        }
        return prev
    }, "")
    if (replaceStr !== "") {
        return url.replace(replaceStr, `${key}=${newValue}`)
    } else if (addIfNotExists && url.indexOf("?") !== -1) {
        return `${url}&${key}=${newValue}`
    } else if (addIfNotExists) {
        return `${url}?${key}=${newValue}`
    } else {
        return url
    }
}
